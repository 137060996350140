import { useState } from 'react';
import { Grid } from "@mui/material";
import Sortby from "./SortBy.tsx";
import { useNavigate } from "react-router-dom";


function Sorter({location}) {
    
    const navigate = useNavigate()

    const sorterConSx = {
        width: {xs: "100%", md: 960},
        justifyContent: "right",
        textAlign: "right",
        height: {xs: 30, sm: 35, md: 40},
        m: "auto"
    }

    const sorterSx = {
        display: "flex",
        alignItems: "center",
        color: "info.main",
        width: {xs: "25vw", md: 120},
        maxWidth: 120,
        height: "100%",
        ml: "2px"
    }

    const startPath = location.pathname.split("?")[0]

         let [queries, setQueries] = useState([])
         let newQuery = ''

        const getPath = (type, value) => {

                newQuery = type + '=' + value
                // console.log(queries, type)
                let newQueries = []
                if (value === '') {
                    newQueries = queries.filter(q => !q.includes(type))
                } else {
                    newQueries = queries.filter(q => !q.includes(type)) 
                    type === 'sort' && queries.join(',').includes(newQuery) && queries.join(',').includes('desc') ?
                    newQueries.push(newQuery + '&type=asc') :
                    type === 'sort' ?
                    newQueries.push(newQuery + '&type=desc') :
                    newQueries.push(newQuery)
                    // console.log(newQuery, newQueries)
                } 
                queries = newQueries
                setQueries(newQueries)

                let path
                queries.length === 0 ? path = startPath : path = startPath + '?' + queries.join('&')
                navigate(path)
            }

    return (
    //   <Typography component="span" variant="body2"> 
      <Grid container sx={{...sorterConSx}}>
            <Grid item  sx={{...sorterSx}}>
                <Sortby getPath={getPath}/>
            </Grid>
      </Grid>

    //   </Typography>
    )
}

export default Sorter