import { Grid } from "@mui/material";
import Sortby from "./SortBy.tsx";
import { useNavigate } from "react-router-dom";


function Sorter({location}) {
    
    const navigate = useNavigate()

    const sorterConSx = {
        width: {xs: "100%", md: 960},
        justifyContent: "right",
        textAlign: "right",
        height: {xs: 30, sm: 35, md: 40},
        m: "5px auto 0"
    }

    const sorterSx = {
        display: "flex",
        alignItems: "center",
        color: "info.main",
        width: {xs: "25vw", md: 120},
        maxWidth: 120,
        height: "100%",
        ml: "2px"
    }

    const startPath = location.pathname.split("?")[0]

    const sort = location.search
    const regexVal = /(?<=sort=)(.*)(?=&)/
    const regexType = /(?<=type=)(.*)/

    let sortValue 
    sort && (sortValue = sort.match(regexVal)[0])

    let sortType 
    sort && (sortType = sort.match(regexType)[0])

        const getSort = (type, value) => {

                // value === 'release_date' ? sortType = 'asc' : sortType = sortType;
                (sortValue === value && sortType === 'desc') ? sortType = 'asc' : sortType = 'desc'

                const path = startPath + '?sort=' + value + '&type=' + sortType
                navigate(path)
            }

    return (
    //   <Typography component="span" variant="body2"> 
      <Grid container sx={{...sorterConSx}}>
            <Grid item  sx={{...sorterSx}}>
                <Sortby getSort={getSort} sortValue={sortValue}/>
            </Grid>
      </Grid>

    //   </Typography>
    )
}

export default Sorter