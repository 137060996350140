import { Grid, Stack, Link } from "@mui/material"
import { ratesSx, aggLogoSx, } from "../dirStyle"

const Rates = ({m}) => {

    const score = JSON.parse(m.scores)
    const ids = JSON.parse(m.ids)
    const imdbLink = "https://www.imdb.com/title/tt"
    const rtLink = "https://www.rottentomatoes.com/m/"
    const metaLink = "https://www.metacritic.com/movie/"
    const lbxdLink = "https://www.letterboxd.com/film/"

    let metascore, metavotes, rtscore, rtvotes, lbxdscore, lbxdvotes, lbxdstr
    score.meta_score ? metascore = score.meta_score : metascore = '-'
    score.meta_votes ? metavotes = score.meta_votes : metavotes = '-'
    score.rt_score ? rtscore = score.rt_score : rtscore = '-'
    score.rt_votes ? rtvotes = score.rt_votes : rtvotes = '-'
    score.lbxd_score ? lbxdscore = score.lbxd_score : lbxdscore = '-'
    lbxdscore != 0 ? lbxdscore = lbxdscore : lbxdscore = '-'
    score.lbxd_votes ? lbxdvotes = score.lbxd_votes : lbxdvotes = '-'

    lbxdstr = lbxdvotes.toString()
    // console.log(lbxdstr)

    if (lbxdstr.length > 6) {
        lbxdstr = lbxdstr = lbxdstr.substring(0, lbxdstr.length - 6) + " " 
            + lbxdstr.substring(1, lbxdstr.length - 3) + " " + lbxdstr.substring(4, lbxdstr.length)
    } else if (lbxdstr.length > 3) {
        lbxdstr = lbxdstr.substring(0, lbxdstr.length - 3) + " " 
     + lbxdstr.substring(lbxdstr.length - 3, lbxdstr.length)
    } else { lbxdstr = lbxdstr }

    return (

            <Grid container item sx={{...ratesSx}}>
                <Grid item sx={{width: {xs: "4.16vw", md: 40}}}>
                    <Stack  sx={{height: "100%", justifyContent: "space-evenly", textAlign: "center", padding: "15px 0 17px"}}>
                            <Link href={lbxdLink + ids.lbxd_id} target="_blank" rel="noopener noreferrer"><Grid component="img" src="../logo/lbxd_logo.png" sx={{...aggLogoSx}}></Grid></Link>
                            <Link href={imdbLink + ids.imdb_id} target="_blank" rel="noopener noreferrer"><Grid component="img" src="../logo/imdb_logo.png" sx={{...aggLogoSx}}></Grid></Link>
                            <Link href={metaLink + ids.meta_id} target="_blank" rel="noopener noreferrer"><Grid component="img" src="../logo/meta_logo.png" sx={{...aggLogoSx}}></Grid></Link>
                            <Link href={rtLink + ids.rt_id} target="_blank" rel="noopener noreferrer"><Grid component="img" src="../logo/rt_logo.png" sx={{...aggLogoSx}}></Grid></Link>
                    </Stack>
                </Grid>
                <Grid item sx={{width: {xs: "6.25vw", md: 60}}}>
                    <Stack sx={{height: "100%", justifyContent: "space-evenly", padding: "15px 0"}}>
                            <Grid item >{lbxdscore}</Grid> 
                            <Grid item>{score.imdb_score}</Grid>
                            <Grid item >{metascore}</Grid> 
                            <Grid item>{rtscore}</Grid>                     
                    </Stack>
                </Grid>
                <Grid item sx={{display: {xs: "none", sm: "block"}, width: {xs: "10.41vw", md: 100}}}>
                    <Stack sx={{height: "100%", justifyContent: "space-evenly", padding: "15px 0"}}>
                            <Grid item >{lbxdstr}</Grid> 
                            <Grid item>{score.imdb_votes_exp}</Grid>
                            <Grid item >{metavotes}</Grid> 
                            <Grid item>{rtvotes}</Grid> 
                    </Stack>
                </Grid>
            </Grid>

    );
}

export default Rates;
