import { Grid, Stack, Link } from '@mui/material';
import { useState, useEffect } from "react"
import { transConSx, transLogoSx, transMenuSx } from "../../theme";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios"
import { useNavigate, Link as RouterLink } from "react-router-dom";

const TranXs = () => {

    const navigate = useNavigate()

    const [coonbase, setCoonbase] = useState([])

    useEffect(() => {
            const getBase = async () => {
                try {
                    const res = await axios.get("/api/coon/")
                    const log = {type: 'info', text: `${res.data.length} movies received from backend`}
                    await axios.post("/api/clientlogs", log)
                    setCoonbase(res.data.full)
                } catch (err) {
                    console.log(err)
                    const log = {type: 'error', text: `${err}`}
                    await axios.post("/api/clientlogs", log)
            }
            }
            getBase()
        }, [])

    const [tr, setTr] = useState([])

    useEffect(() => {
            const getBase = async () => {
                try {
                    const res = await axios.get("/api/translators/")
                    const log = {type: 'info', text: `${res.data.length} translators received from backend`}
                    await axios.post("/api/clientlogs", log)
                    setTr(res.data)
                } catch (err) {
                    console.log(err)
                    const log = {type: 'error', text: `${err}`}
                    await axios.post("/api/clientlogs", log)
            }
            }
            getBase()
        }, [])

    const base = [...new Set([...coonbase.filter(m => JSON.parse(m.subtitles).translators !== null)])]
    const subbed = [...new Set([...base.map(m => {
        return ({
            ...m, 
            sub_date: new Date(JSON.parse(m.subtitles).sub_date),
            sub_add: new Date(JSON.parse(m.subtitles).sub_add),
            translators: JSON.parse(m.subtitles).translators
        })
    })])]
    
    const translators = [...new Set([...tr.map(t => {
        return ({
            ...t, 
            sub_date: new Date(t.last_sub),
        })
    })])]
    translators.sort((a, b) => b.sub_date - a.sub_date)

    return (

        <Grid container item xs={12} sx={{m: "0 auto"}}>
            {translators.map(t => {
                
                const translated = [...new Set(subbed.filter((m) => m.translators.includes(t.name)))]
                
                translated.sort((a, b) => b.sub_date - a.sub_date)

                const logo = t.name_exp + "_logo150.png"

                return (
                <Grid key={t.id} container sx={{...transConSx}}>
                <Grid container>
                <Grid container item sx={{...transLogoSx}}>
                        <div id={t.name_exp}>
                        <RouterLink className="trlogo" to={"/translators/" + t.name_exp.toLowerCase()} onClick={(e) => {e.preventDefault(); navigate("/translators/" + t.name_exp.toLowerCase())}}>
                        <LazyLoadImage
                        height="100%"
                        width="100%"
                        effect="blur"
                        placeholderSrc={"../translators/" + logo}
                        src={"../translators/" + logo}
                        />
                        </RouterLink>
                        </div>
                    </Grid>
                    <Grid container item xs sx={{...transMenuSx, fontSize: "3vw"}}>
                        <Stack direction="row" spacing={0} sx={{width: "100%", justifyContent: "space-around"}}>
                            <Stack>
                            <p>Subbed</p>
                            <p style={{fontSize: 28}}>{translated.length}</p>
                            </Stack>
                            <Stack sx={{height: "inherit"}} >
                            <p>Support</p>
                            <p style={{height: "-webkit-fill-available", display: "flex", justifyContent: "center", alignItems: "center"}}><Link href={t.support} target="_blank" sx={{":hover": {opacity: "0.7", transform: "scale(1.2)"}}}>
                            <img alt="" width="30px" src="../images/piggybank_gold.png"/>
                            </Link></p>
                            </Stack>
                            <Stack>
                            <p>Contact</p>
                            <p style={{height: "-webkit-fill-available", display: "flex", justifyContent: "center", alignItems: "center"}}><Link href={t.contact} target="_blank" sx={{":hover": {opacity: "0.7", transform: "scale(1.2)"}}}>
                            <img alt="" style={{alignSelf: "center"}} width="30px" src="../logo/telegram_logo.png"/></Link></p>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container sx={{height: {xs: "27.5vw"}, mt: "10px"}}>
                {translated.slice(0, 5).map(m => {

                    const ids = JSON.parse(m.ids)
                    const posters = JSON.parse(m.posters)

                    return (
                    <Grid key={ids.imdb_id}  item sx={{height: "100%", width: "20vw", border: "solid", borderWidth: "1vw", borderColor: "transparent", ":hover": {opacity: "0.9", borderColor: "white"}}}>
                        <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                            <LazyLoadImage
                            height="100%"
                            width="100%"
                            style={{objectFit: "cover"}}
                            effect="blur"
                            placeholderSrc={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                            src={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                            />
                        </RouterLink>
                    </Grid>
                )})}
                </Grid>
                
            </Grid>
                )}
            )}
        </Grid>

    )
}

export default TranXs;


