import { useState } from 'react';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Grid, Card, CardMedia, CardContent, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import {posterSx, titleSx} from "../../../theme"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Description from './Description';

export const Mainmoviecard = ({movie}) => {

    const navigate = useNavigate()

    const ids = JSON.parse(movie.ids)
    const main = JSON.parse(movie.maininfo)
    const release = JSON.parse(movie.releaseinfo)
    const posters = JSON.parse(movie.posters)

    const [isShown, setIsShown] = useState(false);

    const location = useLocation()
    const path = location.pathname.split("/")[1]

    const posterPath = '../posters/'

    return (
            <Grid container item sx={{width: {xs: "43.75vw", md: 430}, textAlign: "left"}}>
                  <Card sx={{overflow: "visible", display: "flex", bgcolor: "transparent", color: "primary.main", boxShadow: "unset"}}>
                      <CardMedia 
                        id={ids.cin_id}
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                        sx={{...posterSx}}
                      >
                        <LazyLoadImage
                          effect="blur"
                          placeholderSrc={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                          src={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                          height="100%"
                          width="100%"
                          style={{objectFit: "cover"}}
                          />
                          <Description isShown={isShown} ids={ids} main={main} release={release}/>
                      </CardMedia>
                      <CardContent
                      sx={{width: {xs: "27.08vw", md: 319}, padding: 0, "&:last-child": {padding: 0}}}
                      >
                        <Grid container sx={{height: "100%"}}>
                            <Stack spacing={{xs:0}} sx={{width: {xs: "85%", sm: "95%"}, height: "100%", justifyContent: "center"}}>
                                <Grid item sx={{...titleSx}}>
                                    <RouterLink className='left' to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                    {main.imdb_title_exp}
                                    </RouterLink>
                                </Grid>

                                {(path.includes("directors")) ? 

                                <Grid item sx={{fontSize: {xs: "1.875vw", md: 16}, padding: {xs: "5px 0", lg: "6px 0"}}}>{main.imdb_genre}</Grid> 

                                :

                                <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}, padding: {xs: "5px 0", lg: "6px 0"}}}>{main.imdb_director}</Grid> 
                                }
                                
                                {(path.includes("directors")) ? 

                                <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}}}>{release.year}</Grid> 

                                :

                                <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}}}>{main.imdb_genre}</Grid> 
                                }
                                

                            </Stack>
                        </Grid>
                      </CardContent>
                  </Card>
            </Grid>
    );
}
