import React, { useContext } from "react";
import {Routes, Route, Navigate} from "react-router-dom"
import Years from "./pages/Years"
import Home from "./pages/Home"
import Subtitles from "./pages/Subtitles";
import Translators from "./pages/Translators";
import SingleTr from "./pages/SingleTr";
import { AuthContext } from "./context/AuthContext";
import Movie from "./pages/Movie";
import Directors from "./pages/Directors";
import Director from "./pages/SingleDir"
import Donations from "./pages/Donations";
import Awards from "./pages/Awards";
import SingleAw from "./pages/SingleAw";

export const AppRoutes = () => {
    const { user } = useContext(AuthContext)
    return (
        <Routes>
                <React.Fragment>
                    <Route path="/" element={<Home />} />
                    <Route path="/movies" element={<Years />} />
                    <Route path="/years" element={<Navigate replace to="/years/2024"/>} />
                    <Route path="/years/:id" element={<Years />} />
                    <Route path="/subtitles" element={<Subtitles />} />
                    <Route path="/translators" element={<Translators />} />
                    <Route path="/translators/:trId" element={<SingleTr />} />
                    <Route path="/movies/:movieId" element={<Movie />} />
                    <Route path="/directors/:dirId" element={<Director />} />
                    <Route path="/directors" element={<Directors />} />
                    <Route path="/donations" element={<Donations />} />
                    <Route path="/events" element={<Awards />} />
                    <Route path="/events/:evId/years/:id" element={<SingleAw />} />
                    <Route path="/events/:evId/nominations/:id" element={<SingleAw />} />
                </React.Fragment>
        </Routes>
    )
}