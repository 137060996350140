import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom";
import { mainSx } from "./movieStyle";
import { textureSx } from "../../theme";
import { Grid } from "@mui/material"
import Downl from "./parts/Downl"
import Donate from "./parts/Donate"
import Info from "./parts/Info"
import InfoXs from "./parts/InfoXS"
// import Ranks from "./singleMovie/Ranks"
import Ratings from "./parts/Ratings"
import Streams from "./parts/Streams"
import useWindowDimensions from "../../functions/windowDimensions"
import axios from "axios"


const SingleMovie = () => {

    const { clientWidth } = useWindowDimensions();

    // const [fund, setFund] = useState([]);

    const location = useLocation()
    const path = location.pathname.split("/")[2]
    const params = {path: path}

   // SETTING DATABASE

   const [movie, setMovie] = useState({})
   const [yearRank, setYearRank] = useState()
   const [rank, setRank] = useState()

    useEffect(() => {
            const getBase = async () => {
                try {
                    const res = await axios.post("/api/coon/single", params, {
                        responseType: 'json',
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                        }
                      })
                    // console.log(res.data)
                    setMovie(res.data.movie)
                    setYearRank(res.data.yearRank)
                    setRank(res.data.rank)
                } catch (err) {
                    console.log(err)
                    const log = {type: 'error', text: `${err}`}
                    await axios.post("/api/clientlogs", log)
            }
            }
            getBase()

            const getTag = async () => {
                try {
                    const res = await axios.get("/api/tag/movie")
                } catch (err) {
                    console.log(err)
            }
            }
            getTag()
        }, [])

        // console.log(movie)

   return (
        <Grid container sx={{...mainSx, ...textureSx}}>

            {rank > 0 &&
                <>
                {(clientWidth > 600) ?
                    <Info 
                    movie={movie}
                    />
                    :
                    <InfoXs
                    movie={movie}
                    />}
                    <Ratings  
                    movie={movie} yearRank={yearRank} rank={rank}
                    />
                    <Grid container sx={{padding: {xs: "2.5vw 0 2.5vw 2.5vw", md: "20px 0 20px 80px"}}}>
                        <Streams 
                        movie={movie}
                        />
                        <Downl 
                        movie={movie}
                        />
                    </Grid>
                    {(movie.wallet_id === null || movie.wallet_id === '' || movie.wallet_id.includes("out of")) ?
                    null
                    :
                    <Grid container sx={{padding: {xs: "2.5vw 0 2.5vw 2.5vw", md: "20px 0 20px 80px", justifyContent: "right"}}}>
                        <Donate movie={movie}/>
                    </Grid>
                    }
                </>
            }
        </Grid>
   )
}

export default SingleMovie;
