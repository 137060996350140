import { Box } from '@mui/material';
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom";
import axios from "axios"
import Navbar from '../components/navbar/Navbar';
import useWindowDimensions from "../functions/windowDimensions"
import DirMd from '../components/directors/DirMd';
import DirXs from '../components/directors/DirXs';
import Sorter from '../components/directors/sorter/Sorter';

const Directors = () => {

    const { clientWidth } = useWindowDimensions();
    const location = useLocation()
    const params = location

    const [coonbase, setCoonbase] = useState([])
    const [dir, setDir] = useState([])

    useEffect(() => {
            const getBase = async () => {
                try {
                    const res = await axios.post("/api/directors/", params)
                    const log = {type: 'info', text: `${res.data.full.length} movies received from backend`}
                    await axios.post("/api/clientlogs", log)
                    setCoonbase(res.data.full)
                    setDir(res.data.dir)
                } catch (err) {
                    console.log(err)
                    const log = {type: 'error', text: `${err}`}
                    await axios.post("/api/clientlogs", log)
            }
            }
            getBase()
        }, [params])

    return (
        <>
                <Navbar />
                <Box sx={{textAlign: "center"}}>

                    {(clientWidth > 600) ? 

                    <>
                    <Sorter location={location} />
                    <DirMd coonbase={coonbase} dir={dir}/>
                    </>

                    :
                    
                    <>
                    <Sorter location={location} />
                    <DirXs coonbase={coonbase} dir={dir}/>
                    </>
                    
                    }
                </Box>
         </>
    )
}

export default Directors;


