import { useState, useEffect } from 'react';
import axios from 'axios'
import Navbar from '../components/navbar/Navbar';
import { Box, Grid, Typography } from "@mui/material"
import useWindowDimensions from "../functions/windowDimensions"
import { MenuposterL } from '../components/home/MenuposterL';
import { MenuposterS } from '../components/home/MenuposterS';
import MenuBlock from '../components/home/MenuBlock';
import SubBlock from '../components/home/SubBlock'
import DonateBlock from '../components/home/DonateBlock'
import SearchBlock from '../components/home/SearchBlock'
import 'react-lazy-load-image-component/src/effects/blur.css';

const Home = () => {

    const { clientWidth } = useWindowDimensions();

    const [subbase, setSubbase] = useState([])
    const [donbase, setDonbase] = useState([])

    useEffect(() => {
            const getBase = async () => {
                try {
                    const res = await axios.get("/api/coon/homesd")
                    const log = {type: 'info', text: `${res.data.don.length} movies received from backend`}
                    await axios.post("/api/clientlogs", log)
                    setSubbase(res.data.sub)
                    setDonbase(res.data.don)
                    // console.log(Date.now() - res.data.startTime)
                } catch (err) {
                    console.log(err)
                    const log = {type: 'error', text: `${err}`}
                    await axios.post("/api/clientlogs", log)
            }
            }
            getBase()
        }, [])

        

    return (
            <Typography component="span" variant="h2"> 
                <Navbar />
                <Box sx={{textAlign: "center"}}>
                    <Grid container item sm={12} sx={{m: "0 auto", width: {xs: "100%"}}}>

                        {(clientWidth > 900) ? <MenuposterL /> : <MenuposterS />}

                        <Typography component="span" variant="body1" sx={{m: "0 auto", width: {xs: "100%"}}}>
                            <MenuBlock />
                            <SubBlock coonbase={subbase}/>
                            <DonateBlock coonbase={donbase}/>
                            <SearchBlock />
                        </Typography>

                    </Grid>
                </Box>
            </Typography>
    )
}

export default Home;
