import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Navbar from '../components/navbar/Navbar';
import useWindowDimensions from "../functions/windowDimensions"
import DirMd from '../components/singledir/DirMD';
import DirXs from '../components/singledir/DirXS';
import axios from 'axios';

const SingleDir = () => {

    const { clientWidth } = useWindowDimensions();

    const [tr, setTr] = useState([])

    useEffect(() => {
        const getTors = async () => {
            const res = await axios.get("/api/translators/")
            setTr(res.data)
        }
        getTors()
    }, [])

    const tr_names = [...new Set(tr.map(t => t.name_exp))]

    return (
        <>
                <Navbar />
                <Box sx={{textAlign: "center"}}>

                    {(clientWidth > 600) ? 

                    <DirMd tr_names={tr_names} />

                    :

                    <DirXs tr_names={tr_names} />
                    
                    }
                </Box>
         </>
    )
}

export default SingleDir;


