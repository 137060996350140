import { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Card, CardMedia, CardContent, Stack, Grid, CircularProgress, Box } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { titleSx } from "../../theme";
import { posterSx, percentSx, circleSx, stackSx, boxSx, logoSx } from "./donStyle";

function FinishDon({movie, posterPath, tr}) {

  const navigate = useNavigate()

  const ids = JSON.parse(movie.ids)
  const main = JSON.parse(movie.maininfo)
  const release = JSON.parse(movie.releaseinfo)
  const posters = JSON.parse(movie.posters)
  const don = movie.donations

  const [color, setColor] = useState("lightsalmon")

  const percent = don.percent

    useEffect(() => {
        const getFund = async () => {
            try {

                if (percent > 66) {
                  setColor("#8FDD92")
                } else if (percent > 33) {
                  setColor("khaki")
                }

                // console.log(percent)
            }
            catch(error){
                console.error(error)
            }
        }
        getFund()
    
    }, [])

    const trInfo = [...new Set(tr.filter((t) => t.name.includes(don.pot_tr)))]

    let translator

    if (don.pot_tr === "not assigned") {
        translator = "not assigned"
    } else {
        translator = trInfo[0].name_exp
    }

    let raised
    if (percent > 100) {
        raised = 100
    } else {
        raised = percent
    }

    // circle colour


    return(
      <Grid container sx={{m: "0 0 10px", bgcolor: "rgba(237, 230, 219, 0.05)"}}>

            <Grid container item sx={{width: {xs: "50vw", md: 480}, textAlign: "left"}}>
                  <Card sx={{overflow: "visible", display: "flex", bgcolor: "transparent", color: "primary.main", boxShadow: "unset"}}>
                      <CardMedia 
                        sx={{...posterSx}}
                      >
                        <div id={ids.cin_id}>
                        <LazyLoadImage
                          effect="blur"
                          placeholderSrc={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                          src={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                          height="100%"
                          width="100%"
                          style={{objectFit: "cover"}}
                          />
                        </div>
                          </CardMedia>
                      <CardContent
                      sx={{width: {xs: "34.08vw", md: 344}, padding: 0, "&:last-child": {padding: 0}}}
                      >
                        <Grid container sx={{height: "100%"}}>
                            <Stack spacing={{xs:0}} sx={{width: {xs: "85%", sm: "95%"}, height: "100%", justifyContent: "center"}}>
                                <Grid item sx={titleSx}>
                                    <RouterLink className='left' to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                    {main.imdb_title_exp}
                                    </RouterLink>
                                </Grid>
                                <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}, padding: {xs: "0.71vw 0", sm: "5px 0", lg: "6px 0"}}}>{main.imdb_director}</Grid> 
                                <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}, padding: {xs: "0 0 0.7vw 0", sm: "0 0 5px 0", lg: "0 0 6px 0"}}}>{main.imdb_genre}</Grid> 
                                <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}}}>{release.year}</Grid> 

                            </Stack>
                        </Grid>
                      </CardContent>
                  </Card>
            </Grid>
            <Grid container item sx={{height: "100%", width: {xs: "25vw", md: 240}, justifyContent: "center"}}>
                    <Stack spacing={{xs:0}} sx={stackSx}>
                      <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}}}>RAISED</Grid> 
                      <Grid container item sx={percentSx}>
                          <Box sx={boxSx}>
                            <CircularProgress variant="determinate" value={raised} sx={{color: {color}}} size={"90%"}/>
                            <CircularProgress variant="determinate" value={100} sx={circleSx} size={"90%"}/>
                            <Box
                              sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                             {percent + "%"}
                            </Box>
                          </Box>
                      </Grid>
                      <Grid item sx={{fontSize: {xs: "1.875vw", md: 16}}}>{don.wallet_id}</Grid>
                  </Stack>
            </Grid>
            <Grid container item sx={{width: {xs: "16.67vw", md: 160}, justifyContent: "center"}}>
                    <Stack spacing={{xs:0}} sx={stackSx}>
                          <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}}}>TRANSLATOR</Grid> 
                          <Grid item sx={logoSx}> 

                          {( translator !== "not assigned" ) ?
                          
                          <RouterLink to={"/translators/" + translator.toLowerCase()} 
                          onClick={(e) => {e.preventDefault(); navigate("/translators/" + translator.toLowerCase())}}>
                              <LazyLoadImage
                              effect="blur"
                              placeholderSrc={"../translators/" + translator + "_logo150.png"}
                              src={"../translators/" + translator + "_logo150.png"}
                              // height="100%"
                              width="100%"
                              style={{objectFit: "cover"}}
                              />
                          </RouterLink>
                          :
                            <LazyLoadImage
                              effect="blur"
                              placeholderSrc={"../images/question.png"}
                              src={"../images/question.png"}
                              // height="100%"
                              width="100%"
                              style={{objectFit: "cover"}}
                              />
                          }
                          </Grid> 
                      
                  </Stack>
            </Grid>

      </Grid>
    )
}

export default FinishDon