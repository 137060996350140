import { Grid, Typography } from "@mui/material"
import SearchAppBar from "./SearchMD";
import Searchauto from "./SearchautoMD";
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom'

function Headline({inputHandler}) {
    
    const navigate = useNavigate()

    const location = useLocation()
    const path = location.pathname.split("/")[1]

    return (
      <Typography variant="h1"> 
          <Grid container sx={{justifyContent: "space-between"}}>
              <Grid item xs={6} sx={{fontSize: {xs: "80%", sm: "90%", md: "100%"}, textAlign: "left"}}>
                  {/* <Link href="/" underline="none" sx={{color: "primary.main", ":hover": {color: "info.dark", opacity: "0.7"}}}> */}
                  <RouterLink className="left" to="/" onClick={(e) => {e.preventDefault(); navigate("/")}}>
                        Cinemacoon
                  </RouterLink> 
              </Grid>
              <Grid item sx={{textAlign: "right"}}>
                        {path === "years" && <SearchAppBar inputHandler={inputHandler}/>}
                        {path === "movies" && <Searchauto inputHandler={inputHandler}/> }
              </Grid>
          </Grid>
      </Typography>
    )
}

export default Headline