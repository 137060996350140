// import IconButton from '@mui/material/IconButton';
import { Grid } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';

const Burger = () => {
    return (
            <Grid item sx={{display: "flex", alignItems: "center", textAlign: "left", m: {xs: "0 10px", md: "0 20px 0 0"}}}>
                  {/* <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ m: 0, padding: 0}}
                  >
                  <MenuIcon sx={{":hover": {color: "info.dark"}}}/>
                  </IconButton> */}
            </Grid>
    );
}

export default Burger;
