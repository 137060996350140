import { AppBar, Toolbar, Box, Grid } from '@mui/material/';
import Headlinemd from './parts/HeadlineMD';
import Headlinexs from './parts/HeadlineXS';
import { navbarSx } from "../../theme";
import useWindowDimensions from "../../functions/windowDimensions"
import Burgermd from './parts/BurgerMD';
import Accountmd from './parts/AccountMD';
import Burgerxs from './parts/BurgerXS';
import Accountxs from './parts/AccountXS';
import SearchAppBar from "./parts/SearchXS";
import Searchauto from "./parts/SearchautoXS";
import { useLocation } from 'react-router-dom';

export default function Navbar({inputHandler}) {

    const { clientWidth } = useWindowDimensions();

    const location = useLocation()
    const path = location.pathname.split("/")[1]

    // console.log(path2)

  return (

    <Box>
      <AppBar position="static" sx={{...navbarSx}}>
        <Toolbar disableGutters={true} sx={{height: "100%", minHeight:{xs:0}}}>

            {(clientWidth > 1260) ? 
            <Grid container sx={{alignItems: "center", justifyContent: "center"}}>
              <Burgermd />
              <Grid item sx={{width: {xs: "100%", md: 960}}}>
                <Headlinemd inputHandler={inputHandler} />
              </Grid>
              <Accountmd />
            </Grid>
            :
            <Grid container sx={{position: "relative", zIndex: 100, height: "100%", width: {xs: "100%", sm: 960}, padding: 0, m: {md: "0 auto"}, alignItems: "center", justifyContent: "right"}}>
              <Burgerxs />
              <Headlinexs inputHandler={inputHandler} />
              {path === "years" && <SearchAppBar inputHandler={inputHandler}/>}
              {path === "movies" && <Searchauto inputHandler={inputHandler}/>}
              <Accountxs />
            </Grid>
            }
        </Toolbar>
      </AppBar>
    </Box>
  );
}