import { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { Grid, Stack } from '@mui/material';
import { mainSx, dirLogoSx, dirMenuSx } from "./dirStyle.js";
import { textureSx, transConSx } from "../../theme.js";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios"
import Sorter from "./sorter/Sorter.js"
import { FlagWrap  } from '../directors/dirSx';
import { Mainmoviecard } from './parts/MainMovieCard.js';
import Rates from './parts/Rates.js';
import Streaming from './parts/Streaming.js';
import Download from './parts/Download.js';

const Dir = ({tr_names}) => {

    const navigate = useNavigate()

    const location = useLocation()
    const path = location.pathname.split("/")[2]
    const params = location
    // console.log(path)

    const [coonbase, setCoonbase] = useState([])
    const [dir, setDir] = useState([])
    const [pview, setPview] = useState(false)

    useEffect(() => {
            const getBase = async () => {
                const res = await axios.post(`/api/directors/${path}`, params, {
                    responseType: 'json',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    }
                  })
                setCoonbase(res.data.full)
                setDir(res.data.dir)
            }
            getBase()
        }, [params])

        const f = {
            fontSize: {xs: "1.8vw", md: 15}, 
            fontWeight: 500,
            lineHeight: 1.9
        }

    let nations = []
    dir.nation && (nations = dir.nation.replaceAll(' ', '_').split(',_'))

    let birth 
    dir.birth_day ? birth = dir.birth_day + ', ' + dir.birth_year : dir.birth_year ? birth = dir.birth_year : birth = null
    dir.birth_place ? birth = birth + ' in ' + dir.birth_place : birth = birth

    let death 
    dir.death_day ? death = dir.death_day + ', ' + dir.death_year : dir.death_year ? death = dir.death_year : death = null
    dir.death_place ? death = death + ' in ' + dir.death_place : death = death

    const subbed = [...new Set([...coonbase.filter(d => d.rusub === 'rus')])]

    return (

        <Grid container item sm={12} sx={{m: "0 auto 10px", width: {xs: "100%", sm: 960}}}>
                <Grid container sx={{...transConSx}}>
                    <Grid container item sx={{...dirLogoSx}}>
                        <LazyLoadImage
                        height="100%"
                        width="100%"
                        effect="blur"
                        placeholderSrc={"../directors/" + dir.cin_id + ".jpg"}
                        src={"../directors/" + dir.cin_id + ".jpg"}
                        />
                    </Grid>
                    <Grid container item sx={{...dirMenuSx, ml: "5px"}}>
                        <Stack spacing={0} sx={{justifyContent: "space-between", padding: '5px 0', width: "100%", height: "100%", textAlign: "left"}}>
                            <Grid>
                            <Grid sx={{fontSize: 24, fontWeight: 700}}>{dir.name}</Grid>
                            {birth && <Grid sx={f}>
                                <span style={{color: "silver"}}>born:</span>
                                {" " + birth}</Grid>}
                            {death && <Grid sx={f}>
                            <span style={{color: "silver"}}>died:</span>
                            {" " + death}</Grid>}
                            </Grid>
                            <Grid sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Grid sx={{display: 'flex'}}>
                                    {nations.map(n => {
                                    return (
                                        <Grid key={n} sx={{mr: {xs: '1vw', sm: '0.8vw', md: '7px'}, height: {xs: '3vw', sm: '2.7vw', md: '24px'}}}>
                                            <FlagWrap key={n}>
                                                <LazyLoadImage
                                                    height="100%"
                                                    effect="blur"
                                                    placeholderSrc={"../flags/" + n.toLowerCase() + ".jpg"}
                                                    src={"../flags/" + n.toLowerCase() + ".jpg"}
                                                    />
                                            </FlagWrap>
                                        </Grid>
                                        )
                                    }
                                    )}
                                </Grid>
                                <Grid sx={{display: 'flex'}}>
                                    <Grid sx={f}><span style={{color: "silver"}}>movies:</span>{' ' + coonbase.length}</Grid>
                                    <Grid sx={{...f, ml: '10px'}}><span style={{color: "silver"}}>subitles:</span>{' ' + subbed.length}</Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Sorter location={location}/>
                    {pview &&
                    <Grid container item xs sx={{height: {xs: "16.66vw", md: 153}, m: " auto"}}>
                    {coonbase.length > 0 && coonbase.map(m => {

                        const ids = JSON.parse(m.ids)
                        const main = JSON.parse(m.maininfo)
                        const release = JSON.parse(m.releaseinfo)
                        const posters = JSON.parse(m.posters)

                        return (
                        <Grid key={ids.imdb_id}  container item sx={{height: "100%", width: {xs: "10.5vw", md: 106.6}}}>
                            <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                <LazyLoadImage
                                height="100%"
                                width="100%"
                                effect="blur"
                                title={main.imdb_title_exp + " (" + release.year + ")"}
                                style={{objectFit: "cover"}}
                                placeholderSrc={'../posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                src={'../posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                />
                            </RouterLink>
                        </Grid>
                    )})}
                    </Grid>
                    }
                    {(!pview && coonbase.length > 0)
                    && coonbase.map(m => {
                    
                    return (
                    <Grid key={m.imdb_id} container sx={{...mainSx, ...textureSx}}>

                        <Mainmoviecard m={m}/>
                        <Rates m={m}/>
                        <Grid container item xs sx={{display: {xs: "none", sm: "flex"}, paddingLeft: "10px", alignItems: "center", justifyContent: "center", width: {xs: "17.1875vw", md: 165}}}>
                            <Streaming m={m} />
                        </Grid>
                        <Grid container item sx={{display: {xs: "none", sm: "flex"}, alignItems: "center", justifyContent: "center", width: {xs: "17.1875vw", md: 165}}}>
                            <Download m={m} tr_names={tr_names}/>
                        </Grid>
    
                    </Grid>
                    )
                    })}
                    
                </Grid>
        </Grid>
    )}

export default Dir;
