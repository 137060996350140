import { Grid, Typography } from "@mui/material"
import { useNavigate, Link as RouterLink } from 'react-router-dom'

function Headline() {

    const navigate = useNavigate()
    
    return (
     
              <Grid item xs sx={{position: "relative", zIndex: 0, height: "100%", display: "flex", alignItems: "center"}}>
                  <Typography variant="h1" sx={{display: "flex", textAlign: "left"}}>
                    <Grid sx={{fontSize: {xs: "6vw", sm: "90%", md: "100%"}}}>
                        <RouterLink className="left" to="/" onClick={(e) => {e.preventDefault(); navigate("/")}}>
                                Cinemacoon
                        </RouterLink> 
                    </Grid>
                  </Typography>
              </Grid>

    )
}

export default Headline