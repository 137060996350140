import { useState } from 'react';
import { mainSx } from "../dirStyle";
import { Grid } from '@mui/material';
import Moviecard from './MainMovieCardMobile'
import Rates from './RatesMobile'
import Streamcollapse from './StreamCollapse'


const Movie = ({m, tr_names}) => {

    const [isChecked, setIsChecked] = useState(false);

    return (
    <Grid container sx={{...mainSx}}>

    <Moviecard m={m} isChecked={isChecked} setIsChecked={setIsChecked}/>
    <Rates m={m}/>
    <Streamcollapse m={m} isChecked={isChecked} tr_names={tr_names}/>

    </Grid>
    )
}

export default Movie