import { useState, useContext } from 'react';
// import AccountCircle from '@mui/icons-material/AccountCircle';
import { Grid, IconButton, Link } from '@mui/material';
import { AuthContext } from '../../../context/AuthContext';
// import AccountMenu from './AccountMenu';
// import { Login } from '@mui/icons-material';


const Account = () => {

    const { user, dispatch } = useContext(AuthContext)

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
      };

    const handleLogout = () => {
      dispatch({ type: "LOGOUT" })
    }

    const linkStyle = {
      color: "inherit",
      textDecoration: "none",
      ":hover": {color: "info.dark", opacity: "0.7"}
  }

    return (

      <>
        {user ?
        <Grid item sx={{width: 100, textAlign: "right"}}>
            {/* <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{ m: 0, padding: 0, ":hover": {color: "info.dark"}}}
            >
            <AccountCircle />
            </IconButton>
            <AccountMenu logout={handleLogout} handleClose={handleClose} anchorEl={anchorEl} /> */}
        </Grid>
        :
        <Grid item sx={{width: 100, textAlign: "right"}}>
            {/* <Link href="/login" sx={linkStyle}><Login /></Link> */}
        </Grid>
        }
      </>
        
    );
}

export default Account;
