import { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { Grid, Stack, Link } from '@mui/material';
import { transLogoSx, transMenuSx } from "./trStyle";
import { transConSx } from "../../theme";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios"
import Sorter from "./sorter/Sorter"

const TrMd = () => {

    const navigate = useNavigate()

    const location = useLocation()
    const path = location.pathname.split("/")[2]
    const params = location
    // console.log(path)

    const [tr, setTr] = useState([])

    useEffect(() => {
            const getBase = async () => {
                const res = await axios.get("/api/translators/" + path, {
                    responseType: 'json',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                    })
                setTr(res.data)
            }
            getBase()
        }, [])

    const [coonbase, setCoonbase] = useState([])

    useEffect(() => {
            const getBase = async () => {
                const res = await axios.post(`/api/coon/tr/${path}`, params, {
                    responseType: 'json',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    }
                  })
                setCoonbase(res.data)
            }
            getBase()
        }, [params])

    return (

        <Grid container sx={{m: "0 auto"}}>
                {/* <Grid sx={{fontSize: 32}}>{tran.trName}</Grid>
                <Grid sx={{fontSize: {xs: "1.8vw", md: 15}, fontWeight: 500, color: "info.dark"}}>{"Some " + tran.trName + " info"}</Grid> */}
            <Grid container sx={{...transConSx}}>
                <Grid container item sx={{...transLogoSx}}>
                    <LazyLoadImage
                    height="100%"
                    width="100%"
                    effect="blur"
                    placeholderSrc={"../translators/" + tr.name_exp + "_logo150.png"}
                    src={"../translators/" + tr.name_exp + "_logo150.png"}
                    />
                </Grid>
                <Grid container item xs sx={{...transMenuSx, textTransform: "uppercase", fontSize: "3vw"}}>
                    <Stack spacing={0} sx={{justifyContent: "space-evenly", width: "100%", height: "100%", textAlign: "left"}}>
                        <Stack direction="row" spacing={0} sx={{width: "100%", justifyContent: "space-around"}}>
                            <Stack sx={{textAlign: "center"}}>
                            <p>Subbed</p>
                            <p style={{fontSize: 28}}>{coonbase.length}</p>
                            </Stack>
                            <Stack sx={{height: "inherit"}} >
                            <p>Support</p>
                            <p style={{height: "-webkit-fill-available", display: "flex", justifyContent: "center", alignItems: "center"}}><Link href={tr.support} target="_blank" sx={{":hover": {opacity: "0.7", transform: "scale(1.2)"}}}>
                            <img alt="" width="30px" src="../images/piggybank_gold.png"/>
                            </Link></p>
                            </Stack>
                            <Stack>
                            <p>Contact</p>
                            <p style={{height: "-webkit-fill-available", display: "flex", justifyContent: "center", alignItems: "center"}}><Link href={tr.contact} target="_blank" sx={{":hover": {opacity: "0.7", transform: "scale(1.2)"}}}>
                            <img alt="" style={{alignSelf: "center"}} width="30px" src="../logo/telegram_logo.png"/></Link></p>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                
                
            </Grid>
            <Sorter location={location}/>
                <Grid container sx={{height: {xs: "27.5vw"}}}>
                {coonbase.map(m => {

                    const ids = JSON.parse(m.ids)
                    const main = JSON.parse(m.maininfo)
                    const release = JSON.parse(m.releaseinfo)
                    const posters = JSON.parse(m.posters)

                    return (
                    <Grid key={ids.imdb_id} item sx={{height: "100%", width: "20vw", border: "solid", borderWidth: "1vw", borderColor: "transparent", ":hover": {opacity: "0.9", borderColor: "white"}}}>
                        <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                            <LazyLoadImage
                            height="100%"
                            width="100%"
                            effect="blur"
                            title={main.imdb_title_exp + " (" + release.year + ")"}
                            style={{objectFit: "cover"}}
                            placeholderSrc={'../posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                            src={'../posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}                            
                            />
                        </RouterLink>
                    </Grid>
                )})}
                </Grid>
        </Grid>
    )}

export default TrMd;
