
 // STYLING 

import zIndex from "@mui/material/styles/zIndex"

const titleSx = {
    fontSize: { xs: "3.1vw", sm: 20 }, 
    fontWeight: 700,
    lineHeight: {xs: 1.3, md: 1.2},
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
}

const childtitleSx = {
    fontSize: { xs: "2.5vw", sm: 17 }, 
    fontWeight: 600,
    lineHeight: 1.2,
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
}

const headlineSx = {
    color: "primary.main",
    width: {xs: "100%", md: 960}, 
    height: {xs: "7.5vw", md: 48}, 
    maxHeight: 48,
    m: "10px auto",
    bgcolor: "rgba(237, 230, 219, 0.06)",
    // padding: {xs: "2vw", md: "10px 20px"},
    fontSize: {xs: "2vw", md: 18},
    fontWeight: 600
}

const mainHeaderSx = {
    color: "primary.main", 
    width: {xs: "100%", md: 960},
    display: {xs: "flex"}, 
    // bgcolor: "rgba(236, 239, 241, 0.95)", 
    bgcolor: "rgba(255,255,255,0.12)",
    // bgcolor: "rgba(237, 230, 219, 0.1)",
    padding: "7px 0", 
    m: "0 auto",
    fontSize: { xs: "3.1vw", sm: 20 }
    }

const conSx = {
        // padding: "0", 
        m: "0 auto 0", 
        // color: "primary.main", 
        // flexWrap: 'wrap',
        width: {xs: "100%", md: 960}
        // bgcolor: "rgba(236, 239, 241, 0.95)",
    }

const mainSx = {
    padding: "0", 
    m: "9px auto 0", 
    color: "primary.main", 
    // width: {xs: "100%", md: 960},
    bgcolor: "rgba(237, 230, 219, 0.06)",
    // bgcolor: "rgba(236, 239, 241, 0.95)",
}

const childSx = {
    padding: "2px 5px", 
    m: "9px auto 0", 
    color: "primary.main", 
    width: {xs: "48.5%", md: 475},
    bgcolor: "rgba(237, 230, 219, 0.06)",
    "&:nth-of-type(even)": {
        ml: "10px"
      }
    // bgcolor: "rgba(236, 239, 241, 0.95)",
}

const posterSx = {
    position: "relative",
    height: '25vw', 
    maxHeight: 150,
    width: "16.8vw", 
    maxWidth: 101,
    // mr: "10px"
}

const fontBasicSx = {
    fontSize: {xs: "2.1vw", sm: 16}
}

const placeSx = {
    position: 'relative',
    zIndex: 1,
    bottom: 0,
    paddingTop: '7px',
    fontSize: { xs: "2.7vw", sm: 18 }, 
    fontWeight: 600
}

const firstplaceSx = {
    color: '#F4EED0'
}

const secondplaceSx = {
    color: '#E5E5E5'
}

const thirdplaceSx = {
    color: '#FFE0E0'
}


const coonSx = {
    height: { xs: "6.5vw", md: 45},
    maxHeight: {sm: 40, md: 45}
}

const subLogoSx = {
    m: 'auto',
    padding: "2px", 
    width: { xs: "6.0vw", md: 40},
    maxWidth: {sm: 35, md: 40},
    borderRadius: "50%"
}

const sorterConSx = {
    width: {xs: "100%", md: 960},
    justifyContent: "left", 
    height: {xs: 'auto', sm: 'auto', md: 40},
    m: {xs: "auto", sm: "0 auto"}
}

const selectSx = {
    display: "flex", 
    borderRadius: 0,
      m: {xs: '0 5px 0 0', sm: '0 10px 0 0'},
      ":hover": {
        bgcolor: "rgba(255,255,255,0.3)"},
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
      color: "primary.main",
      height: "100%",
      padding: {xs: "0 6px 0 4px", md: "6px 12px 6px 8px"},
      "& fieldset": {border: "none"},
      "& .MuiOutlinedInput-input": {
        fontSize: {xs: 11, md: 14}, 
        padding: 0}
}

const itemSx = {
    width: 'auto',
    fontSize: {xs: "2.3vw", sm: 14},
    minHeight: "auto",
    padding: {xs: "2px 0", sm: "3px 12px"},
    ":hover": {
        color: "primary.main", 
        backgroundColor: "info.dark"},
    "&.Mui-selected": {
        color: "primary.main", 
        backgroundColor: "info.dark",
        ":hover": {
            backgroundColor: "info.main",
            color: "info.dark"
        }
    }
}

const goback = {
    position: 'relative',
    zIndex: 1,
    right: 0,
    height: '100%',
    width: {xs: "6.0vw", sm: '40px'},
    minWidth: '30px'
}

export { titleSx, headlineSx, mainHeaderSx, mainSx, posterSx, childSx, conSx, 
    childtitleSx, coonSx, subLogoSx, selectSx, itemSx, sorterConSx, fontBasicSx, placeSx, firstplaceSx, secondplaceSx, thirdplaceSx, goback}