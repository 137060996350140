import { styled } from '@mui/material/styles';

const mainSx = {
    padding: "0", 
    m: "9px auto 0", 
    color: "primary.main", 
    width: {xs: "100%", md: 960},
    bgcolor: "rgba(237, 230, 219, 0.06)",
    // bgcolor: "rgba(236, 239, 241, 0.95)",
}

// INFO

const PosterWrap = styled('div')(({ theme }) => ({
    width: "28.125vw",
    height: "41.66vw",
    [theme.breakpoints.up('md')]: {
    width: 270,
    height: 400
  },
}))

const InfoWrap = styled('div')(({ theme }) => ({
    textAlign: "left",
    marginLeft: "2vw",
    [theme.breakpoints.up('md')]: {
    marginLeft: "20px",
  },
}))

const TitleBlock = styled('div')(({ theme }) => ({
    lineHeight: 1,
    width: "66.66vw",
    margin: "0 2vw 1vw 0",
    [theme.breakpoints.up('md')]: {
    margin: "0 25px 12px 0",
    width: 640,
  },
}))

const Title = styled('p')(({ theme }) => ({
    marginLeft: "-1px",
    fontSize: "4.5vw",
    padding: "1.2vw 0",
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
    marginLeft: "-2px",
    padding: "10px 0",
    fontSize: 40
  },
}))

const OrigTitle = styled('p')(({ theme }) => ({
    color: "silver",
    fontSize: "2.6vw",
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.up('md')]: {
    fontSize: 14
  },
}))

const InfoRow = styled('div')(({ theme }) => ({
    display: "flex",
    padding: "0.3vw 0",
    width: "66.66vw",
    fontSize: "2.2vw",
    [theme.breakpoints.up('sm')]: {
      fontSize: "1.5vw"
    },
    [theme.breakpoints.up('md')]: {
    padding: "4px 0",
    width: 640,
    fontSize: 14
  },
}))

const Summary = styled('div')(({ theme }) => ({
  display: "flex",
  padding: "2vw",
  textAlign: "left",
  // width: "66.66vw",
  fontSize: "2.2vw"
}))

const Tag = styled('div')(({ theme }) => ({
    display: "flex",
    width: "20%",
    color: "silver",
    [theme.breakpoints.up('md')]: {
    flex: 2
  },
}))

const Content = styled('div')(({ theme }) => ({
    display: "flex",
    flex: 9,
    [theme.breakpoints.up('md')]: {
    flex: 10
  },
}))

// RATINGS

const rankSx = {
  padding: {xs: "1.2vw 2.2vw", md: "10px 20px"}, 
  fontSize: {xs: "3vw", sm: "2vw", md: 18}, 
  fontWeight: 400, 
  color: "silver"
}

const RateWrap = styled('div')(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    flex: 3
}))

const LogoWrap = styled('div')(({ theme }) => ({
    paddingBottom: "10px",
    width: "100%"
}))

const AggLink = styled('a')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
  },
}))

const Logo = styled('img')(({ theme }) => ({
    borderRadius: "50%",
    height: "6vw",
    maxHeight: "50px",
    ":hover": {
      transform: "scale(1.1)",
      opacity: "70%"},
    [theme.breakpoints.up('md')]: {
    height: "50px"
  },
}))

const ScoreWrap = styled('div')(({ theme }) => ({
    padding: "0 0 1vw 0",
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
    padding: "0 0 10px 0"
  },
}))

const Score = styled('div')(({ theme }) => ({
    fontSize: "4.2vw",
    [theme.breakpoints.up('md')]: {
        fontSize: 40,
        fontWeight: 700
  },
}))

const Votes = styled('div')(({ theme }) => ({
    fontSize: "2.3vw",
    color: "silver",
    [theme.breakpoints.up('md')]: {
      fontSize: 16
  },
}))

// STREAMS

const onlLogoSx = {
  padding: "2px", 
  width: { xs: "8.25vw", md: 60},
  maxWidth: 60,
  borderRadius: "50%",
  ":hover": {
      transform: "scale(1.1)",
      opacity: "70%"}
}

const Header = styled('div')(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  textTransform: "uppercase",
  paddingBottom: "1vw",
  display: 'flex',
  fontSize: "3vw",
  fontWeight: 700,
  [theme.breakpoints.up('sm')]: {
  paddingBottom: "10px",
  fontSize: "2.6vw"
  },
  [theme.breakpoints.up('md')]: {
  paddingBottom: "20px",
  fontSize: 20
  },
}))

const StrText = styled('span')(({ theme }) => ({
  color: "silver",
  fontSize: "2.3vw",
  [theme.breakpoints.up('md')]: {
  fontSize: 17
},
}))

const StreamWrap = styled('div')(({ theme }) => ({
  display: "block",
  textAlign: "left",
  // flexWrap: "wrap",
  flex: 6,
  // maxWidth: '50%',
  [theme.breakpoints.up('md')]: {
},
}))

// RANKS

const RankWrap = styled('div')(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flex: 4,
  [theme.breakpoints.up('md')]: {
},
}))

// DONATE

const donateSx = {
  width: "50%"
}

const donBlocks = {
display: "flex", 
width: {xs: "95%", sm: "90%", md: "80%"},
textAlign: 'left', 
m: {xs: "5px 0", sm: "10px 0"}, 
fontSize: {xs: "2.6vw", sm: 17}
}

const buttonSx = {
mt: 3, 
width: { xs: "37.92vw", sm: "27.92vw", md: 220},
borderRadius: "15px",
color: "secondary.main", 
backgroundColor: "primary.main", 
fontSize: { xs: "2.5vw", sm: "2vw", md: 17 },
fontWeight: 700,
":hover": {
   color: "primary.main", 
   backgroundColor: "#90A4AE"},
mb: 2, 
m: "0",
":disabled": {bgcolor: "rgba(255,255,255,0.4)", color: "#90A4AE"}
}

const linkSx = {
  textDecoration: "none"
}

const Numbers = styled('span')(({ theme }) => ({
  marginLeft: "1.5vw", 
  color: "#eceff1", 
  fontSize: "2.6vw",
  [theme.breakpoints.up('sm')]: {
      marginLeft: "10px",
      fontSize: 17
},
}))

const infoIcon = {
  fontSize: { xs: "2.4vw", sm: "2vw", md: 19 },
  m: { xs: 'auto 3px', sm: 'auto 5px' }
}

const infoIconLink = {
  width: 'auto', 
  height: 'inherit',
  cursor: 'pointer',
  ":hover": {
   color: '#90a4ae'
  }
}

export { mainSx, PosterWrap, TitleBlock, Title, InfoWrap, OrigTitle, InfoRow, Tag, Content, RateWrap, LogoWrap, ScoreWrap, 
  Logo, AggLink, Score, Votes, onlLogoSx, StreamWrap, Header, RankWrap, Summary, rankSx, StrText, donateSx, buttonSx, linkSx, donBlocks, Numbers, 
  infoIcon, infoIconLink }