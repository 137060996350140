import { Grid, Stack } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { FlagWrap, dirConSx, dirLogoSx, dirMenuSx  } from './dirSx';

const Dirs = ({coonbase, dir}) => {

    const navigate = useNavigate()

    return (

        <Grid container item xs={12} sx={{m: "0 auto"}}>
            {dir.map(d => {
                
                const directed = [...new Set([...coonbase.filter(m => JSON.parse(m.directors).array_agg.includes(d.imdb_id))])]

                const subbed = [...new Set([...directed.filter(d => d.rusub === 'rus')])]
                
                const logo = d.cin_id + ".jpg"
                const nations = d.nation.replaceAll(' ', '_').split(',_')

                return (
                <Grid key={d.imdb_id} container sx={dirConSx}>
                <Grid container>
                <Grid container item sx={dirLogoSx}>
                        <div>
                        <RouterLink className="dirlogo" to={"/directors/" + d.cin_id} onClick={(e) => {e.preventDefault(); navigate("/directors/" + d.cin_id)}}>
                        <LazyLoadImage
                        height="100%"
                        width="100%"
                        effect="blur"
                        placeholderSrc={"../directors/" + logo}
                        src={"../directors/" + logo}
                        />
                        </RouterLink>
                        </div>
                    </Grid>
                    <Grid container item xs sx={{...dirMenuSx, fontSize: "2.7vw"}}>
                    <Stack spacing={0} sx={{justifyContent: "space-between", padding: {xs: '3px 0', sm: '5px 0'}, width: "100%", height: '100%', textAlign: "left"}}>
                            
                            <Grid >
                                <Grid>
                                    <RouterLink className="left" to={"/directors/" + d.cin_id} 
                                    onClick={(e) => {e.preventDefault(); navigate("/directors/" + d.cin_id)}} >
                                    <Grid sx={{fontSize: { xs: "3.5vw", fontWeight: 700}}}>{d.name}</Grid>
                                    </RouterLink>
                                </Grid>
                                <Grid>
                                {d.death_year && <p>{d.birth_year + " - " + d.death_year}</p>}
                                {(!d.death_year && d.birth_year) && <p>{d.birth_year}</p>}
                                </Grid>
                            </Grid>
                            
                            <Grid sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Grid sx={{display: 'flex'}}>
                                    {nations.map(n => {
                                    return (
                                        <Grid key={n} sx={{mr: '1vw', height: '3vw'}}>
                                            <FlagWrap key={n}>
                                                <LazyLoadImage
                                                    height="110%"
                                                    effect="blur"
                                                    placeholderSrc={"../flags/" + n.toLowerCase() + ".jpg"}
                                                    src={"../flags/" + n.toLowerCase() + ".jpg"}
                                                    />
                                            </FlagWrap>
                                        </Grid>
                                        )
                                    }
                                    )}
                                </Grid>
                                <Grid sx={{display: 'flex'}}>
                                    <Grid ><span style={{color: "silver"}}>movies:</span>{' ' + directed.length}</Grid>
                                    <Grid sx={{ml: '10px'}}><span style={{color: "silver"}}>subitles:</span>{' ' + subbed.length}</Grid>
                                </Grid>
                            </Grid>

                        </Stack>
                    </Grid>
                </Grid>
                <Grid container sx={{height: {xs: "27.5vw"}, mt: "10px"}}>
                {directed.slice(0, 5).map(m => {

                    const ids = JSON.parse(m.ids)
                    const posters = JSON.parse(m.posters)

                    return (
                    <Grid key={ids.imdb_id}  item sx={{height: "100%", width: "20vw", border: "solid", borderWidth: "1vw", borderColor: "transparent", ":hover": {opacity: "0.9", borderColor: "white"}}}>
                        <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                            <LazyLoadImage
                            height="100%"
                            width="100%"
                            style={{objectFit: "cover"}}
                            effect="blur"
                            placeholderSrc={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                            src={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                            />
                        </RouterLink>
                    </Grid>
                )})}
                </Grid>
                
            </Grid>
                )}
            )}
        </Grid>

    )
}

export default Dirs;


