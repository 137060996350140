import { Typography, Grid, Box } from "@mui/material";

function MainHeader() {

    const mainHeaderSx = {
            color: "primary.main", 
            width: {xs: "100%", md: 960},
            display: {xs: "none", sm: "flex"}, 
            // bgcolor: "rgba(236, 239, 241, 0.95)", 
            bgcolor: "rgba(255,255,255,0.12)",
            // bgcolor: "rgba(237, 230, 219, 0.1)",
            padding: "7px 0", 
            m: "0 auto 9px auto"
            }
            
            const headerSx = {
            justifyContent: "center",
            fontSize: { xs: "2.2vw", md: 20 }
            }

        
    return (
      <Typography component="span" variant="h3"> 
      <Box sx={{textAlign: "center"}}>
      <Grid container sx={{...mainHeaderSx}}>
              <Grid item sx={{...headerSx, width: {xs: "43.75vw", md: 430}}} />
              <Grid container item sx={{...headerSx, width: {xs: "20.83vw", md: 200}}}>
                    Ratings
              </Grid>
              <Grid container item xs sx={{...headerSx, display: {xs: "none", sm: "flex"}, width: {xs: "17.1875vw", md: 165}}}>
                    Watch Online
              </Grid>
              <Grid container item sx={{...headerSx, display: {xs: "none", sm: "flex"}, width: {xs: "17.1875vw", md: 165}}}>
                    Download
              </Grid>
      </Grid>
      </Box>
      </Typography>
    )
}

export default MainHeader