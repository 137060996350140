import { LazyLoadImage } from 'react-lazy-load-image-component';
import { InfoWrap, OrigTitle, PosterWrap, Title, Tag, TitleBlock, Content, InfoRow, Summary } from '../movieStyle';
import { Grid } from '@mui/material';

const Info = ({movie}) => {

    const main = JSON.parse(movie.maininfo)
    const release = JSON.parse(movie.releaseinfo)
    const ids = movie.ids
    const posters = JSON.parse(movie.posters)
    const posterPath = '../posters/'

    let origTitle = ""

    if (main.imdb_title !== main.imdb_title_exp) {
        origTitle = main.imdb_title
    }

    return (
        <Grid container sx={{borderBottom: "1px solid #1E2D35"}}>
            <PosterWrap>
                <LazyLoadImage
                    effect="blur"
                    placeholderSrc={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                    src={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                    height="100%"
                    width="100%"
                    style={{objectFit: "cover"}}
                    />
            </PosterWrap>
            <InfoWrap>
                <TitleBlock>
                    <Title>{main.imdb_title_exp}</Title>
                    <OrigTitle>{origTitle}</OrigTitle>
                </TitleBlock>
    
                <InfoRow><Tag>year:</Tag><Content>{release.year}</Content></InfoRow>
                <InfoRow><Tag>runtime:</Tag><Content>{main.imdb_duration}</Content></InfoRow>
                <InfoRow><Tag>country:</Tag><Content>{main.imdb_country}</Content></InfoRow>
                <InfoRow><Tag>genre:</Tag><Content>{main.imdb_genre}</Content></InfoRow>
                <InfoRow><Tag>director:</Tag><Content>{main.imdb_director}</Content></InfoRow>
                <InfoRow><Tag>starring:</Tag><Content>{main.imdb_stars}</Content></InfoRow>
            </InfoWrap>
            <Summary><Content>{main.imdb_description}</Content></Summary>
        </Grid>
    );
}

export default Info;
