import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Grid, Stack } from '@mui/material';
import { dirLogoSx, dirMenuSx } from "./dirStyle";
import { transConSx } from "../../theme";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios"
import Sorter from "./sorter/Sorter"
import { FlagWrap  } from '../directors/dirSx';
import Movie from './parts/Movie';

const Dir = ({tr_names}) => {

    const location = useLocation()
    const path = location.pathname.split("/")[2]
    const params = location
    // console.log(path)

    const [coonbase, setCoonbase] = useState([])
    const [dir, setDir] = useState([])
    // const [pview, setPview] = useState(false)

    useEffect(() => {
            const getBase = async () => {
                const res = await axios.post(`/api/directors/${path}`, params, {
                    responseType: 'json',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    }
                  })
                setCoonbase(res.data.full)
                setDir(res.data.dir)
            }
            getBase()
        }, [params])

    let nations = []
    dir.nation && (nations = dir.nation.replaceAll(' ', '_').split(',_'))

    let birth 
    dir.birth_day ? birth = dir.birth_day + ', ' + dir.birth_year : dir.birth_year ? birth = dir.birth_year : birth = null
    dir.birth_place ? birth = birth + ' in ' + dir.birth_place : birth = birth

    let death 
    dir.death_day ? death = dir.death_day + ', ' + dir.death_year : dir.death_year ? death = dir.death_year : death = null
    dir.death_place ? death = death + ' in ' + dir.death_place : death = death

    const subbed = [...new Set([...coonbase.filter(d => d.rusub === 'rus')])]

    return (

        <Grid container sx={{m: "0 auto"}}>
            <Grid container sx={{...transConSx}}>
                <Grid container item sx={{...dirLogoSx}}>
                    <LazyLoadImage
                    height="100%"
                    width="100%"
                    effect="blur"
                    placeholderSrc={"../directors/" + dir.cin_id + ".jpg"}
                    src={"../directors/" + dir.cin_id + ".jpg"}
                    />
                </Grid>
                <Grid container item sx={{...dirMenuSx, ml: {xs: '0', sm: '5px'}}}>
                        <Stack spacing={0} sx={{justifyContent: "space-between", padding: {xs: '3px 0', sm: '5px 0'}, width: "100%", height: "100%", textAlign: "left"}}>
                            <Grid>
                            <Grid sx={{fontSize: { xs: "4.5vw", fontWeight: 700 }}}>{dir.name}</Grid>
                            {birth && <Grid sx={{m: '5px 0'}}>
                                <span style={{color: "silver"}}>born:</span>
                                {" " + birth}</Grid>}
                            {death && <Grid >
                            <span style={{color: "silver"}}>died:</span>
                            {" " + death}</Grid>}
                            </Grid>
                            <Grid sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Grid sx={{display: 'flex'}}>
                                    {nations.map(n => {
                                    return (
                                        <Grid key={n} sx={{mr: '1vw'}}>
                                            <FlagWrap key={n}>
                                                <LazyLoadImage
                                                    height="100%"
                                                    effect="blur"
                                                    placeholderSrc={"../flags/" + n.toLowerCase() + ".jpg"}
                                                    src={"../flags/" + n.toLowerCase() + ".jpg"}
                                                    />
                                            </FlagWrap>
                                        </Grid>
                                        )
                                    }
                                    )}
                                </Grid>
                                <Grid sx={{display: 'flex'}}>
                                    <Grid ><span style={{color: "silver"}}>movies:</span>{' ' + coonbase.length}</Grid>
                                    <Grid sx={{ml: '10px'}}><span style={{color: "silver"}}>subitles:</span>{' ' + subbed.length}</Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
            </Grid>
            <Sorter location={location}/>
                <Grid container sx={{height: {xs: "27.5vw"}}}>
                {coonbase.length > 0 && coonbase.map(m => {

                    return (<Movie key={m.imdb_id} m={m} tr_names={tr_names}/>
                      
                )})}
                </Grid>
        </Grid>
    )}

export default Dir;
