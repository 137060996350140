import { Grid, Stack } from '@mui/material';
import { useNavigate, Link as RouterLink } from "react-router-dom";

const Description = ({isShown, ids, main, release}) => {

    const navigate = useNavigate()

    const descriptionSx = {
        color: "secondary.main",
        bgcolor: "rgba(255,255,255,0.9)",
        // bgcolor: "rgba(31, 46, 54, 0.95)",
        position: "absolute",
        height: {xs: "16.66vw", md: 150},
        width: {xs: "44vw", md: 430}, 
        m: {xs: "-16.66vw 0 0 11.22vw", md: "-150px 0 0 101px"},
        padding: "7px 10px"
    }

    const desTitleSx = {
        fontSize: {xs: "1.4vw", md: 12}, 
        fontWeight: 700, 
        paddingBottom: {xs: "0.7vw", md: "7px"}
    }

    const desItemSx = {
        fontSize: {xs: "1vw", md: 8}, 
        paddingBottom: "5px",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical"
    }
  
    return (
        <Grid container item sx={{width: "auto"}}>
        {isShown && (
                <Stack spacing={0} sx={descriptionSx}>
                    <Grid item sx={desTitleSx}>
                        <RouterLink className='left desc' to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>{main.imdb_title_exp}</RouterLink>
                    </Grid>
                    <Grid item sx={desItemSx}>{release.year} | {main.imdb_duration} | {main.imdb_country}</Grid> 
                    <Grid item sx={{...desItemSx, WebkitLineClamp: 2}}><b>Starring:</b> {main.imdb_stars} </Grid>
                    <Grid item sx={{...desItemSx, WebkitLineClamp: 3}}><b>Summary:</b> {main.imdb_description} </Grid>
                    {/* {(typeof props.trans === "undefined") ? null : <Grid item sx={{...desItemSx}}><b>Translated by:</b> {props.trans}</Grid>} */}
                </Stack>
          )}
        </Grid>
    )
}

export default Description;
