import {useState, useCallback, useEffect} from 'react';
import _debounce from "lodash.debounce"
import { Grid } from '@mui/material';
import { headlineSx, searchCon } from "./menuStyle";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SearchAppBar from "./Search"
import axios from 'axios'
import { useNavigate, Link as RouterLink } from "react-router-dom";

const SearchBlock = ({}) => {

    const navigate = useNavigate()

    // ---------------------- SEARCH -----------------------------

    const [inputText, setInputText] = useState("");
    function slowHandler (e) {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
    };

    const inputHandler = useCallback(_debounce(slowHandler, 500), [])

    const [coonbase, setCoonbase] = useState([])

    useEffect(() => {
        const getBase = async () => {
            try {
                const res = await axios.post("/api/coon/homesearch", {params: inputText}, {
                    responseType: 'json',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    }
                  })
                // console.log(res.data)
                setCoonbase(res.data.full)
            } catch (err) {
                console.log(err)
                const log = {type: 'error', text: `${err}`}
                await axios.post("/api/clientlogs", log)
        }
        }
        getBase()
    }, [inputText])

    const result = coonbase

    return (
        <Grid>
            <Grid container sx={headlineSx}>
                    <div style={{margin: "auto 10px"}}>SEARCH FOR SOME MOVIES</div>
            </Grid>
            <Grid container sx={searchCon}>
                <SearchAppBar inputHandler={inputHandler} />
            </Grid>
            <Grid container item sm={12} sx={{m: "0 auto 10px", width: {xs: "100%", sm: 960}}}>
                <Grid container item xs sx={{height: {xs: "16.66vw", md: 153}, m: "0 auto"}}>
                    {result.slice(0, 9).map(m => {
                      
                        const ids = JSON.parse(m.item.ids)
                        const main = JSON.parse(m.item.maininfo)
                        const release = JSON.parse(m.item.releaseinfo)
                        const posters = JSON.parse(m.item.posters)

                        return (
                        <Grid key={ids.imdb_id}  container item sx={{height: "100%", width: {xs: "11.1vw", md: 106.6}}}>
                            <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                <LazyLoadImage
                                height="100%"
                                width="100%"
                                effect="blur"
                                title={main.imdb_title_exp + " (" + release.year + ")"}
                                style={{objectFit: "cover"}}
                                placeholderSrc={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                src={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                />
                            </RouterLink>
                        </Grid>
                        )}
                    )}
                </Grid>
            </Grid>
        </Grid>

    )
}

export default SearchBlock;
