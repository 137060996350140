import { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Card, CardMedia, CardContent, Stack, Grid, CircularProgress, Box, Button } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { titleSx } from "../../theme";
import { posterSx, buttonSx, percentSx, circleSx, stackSx, boxSx, logoSx, movieSx } from "./donStyle";
import axios from "axios"
import { load } from 'cheerio'


function ActiveDon({imdb_id, movie, posterPath, tr}) {

  const navigate = useNavigate()

  const walletUrl = 'https://yoomoney.ru/fundraise/' + movie.donations.wallet_id
  const posters = JSON.parse(movie.posters)

  const [fund, setFund] = useState({
    sum: "",
    percent: 0
  })

  const [color, setColor] = useState("lightsalmon")

  useEffect(() => {
    const getFund = async () => {
        try {
            const res = await axios.post(`/api/donations/${imdb_id}`)

            
            const don = res.data[0].d_sum
            let percent = res.data[0].percent

            const regex = /\D/g
            let cur_sum, full_sum
            movie.percent < 100 && (cur_sum = don.replaceAll(' ', ' ').replace('₽ from', '₽ out of').split(' ₽ out of ')[0].replaceAll(' ', '').replace(',', '.'))
            movie.percent < 100 && (full_sum = don.replaceAll(' ', ' ').replace('₽ from', '₽ out of').split(' ₽ out of ')[1].replace(regex, ''))
            percent < 100 ? percent = Math.round(cur_sum / full_sum * 100) : percent = percent

            setFund({
              sum: res.data[0].d_sum,
              percent: percent
            })

            if (percent > 66) {
              setColor("#7DCE82")
            } else if (percent > 33) {
              setColor("khaki")
            }

        }
        catch(error){
            console.error(error)
        }
    }
    getFund()
}, [])

    const trInfo = [...new Set(tr.filter((t) => t.name.includes(movie.donations.pot_tr)))]

    let translator
    if (movie.donations.pot_tr === "not assigned") {
        translator = "not assigned"
    } else {
        translator = trInfo[0].name_exp
    }

    const ids = JSON.parse(movie.ids)
    const main = JSON.parse(movie.maininfo)
    const release = JSON.parse(movie.releaseinfo)

    return(
      <Grid container sx={movieSx}>

            <Grid container item sx={{width: {xs: "50vw", md: 480}, textAlign: "left"}}>
                  <Card sx={{overflow: "visible", display: "flex", bgcolor: "transparent", color: "primary.main", boxShadow: "unset"}}>
                      <CardMedia 
                        sx={posterSx}
                      >
                        <div id={ids.cin_id}>
                        <LazyLoadImage
                          effect="blur"
                          placeholderSrc={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                          src={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                          height="100%"
                          width="100%"
                          style={{objectFit: "cover"}}
                          />
                        </div>
                          </CardMedia>
                      <CardContent
                      sx={{width: {xs: "34.08vw", md: 344}, padding: 0, "&:last-child": {padding: 0}}}
                      >
                        <Grid container sx={{height: "100%"}}>
                            <Stack spacing={{xs:0}} sx={{width: {xs: "85%", sm: "95%"}, height: "100%", justifyContent: "center"}}>
                                <Grid item sx={titleSx}>
                                    <RouterLink className='left' to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                    {main.imdb_title_exp}
                                    </RouterLink>
                                </Grid>
                                <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}, padding: {xs: "0.71vw 0", sm: "5px 0", lg: "6px 0"}}}>{main.imdb_director}</Grid> 
                                <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}, padding: {xs: "0 0 0.7vw 0", sm: "0 0 5px 0", lg: "0 0 6px 0"}}}>{main.imdb_genre}</Grid> 
                                <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}}}>{release.year}</Grid> 

                            </Stack>
                        </Grid>
                      </CardContent>
                  </Card>
            </Grid>
            <Grid container item sx={{height: "100%", width: {xs: "25vw", md: 240}, justifyContent: "center"}}>
                    <Stack spacing={{xs:0}} sx={stackSx}>
                      <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}}}>RAISED</Grid> 
                      <Grid container item sx={percentSx}>
                          <Box sx={boxSx}>
                            <CircularProgress variant="determinate" value={fund.percent} sx={{color: {color}}} size={"90%"}/>
                            <CircularProgress variant="determinate" value={100} sx={circleSx} size={"90%"}/>
                            <Box
                              sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                             {fund.percent + "%"}
                            </Box>
                          </Box>
                      </Grid>
                      <Grid item sx={{fontSize: {xs: "1.875vw", md: 16}}}>{fund.sum }</Grid>
                      <a href={walletUrl} targer='blank' rel='noopener noreferrer'> 
                      <Button
                      type="submit"
                      disableElevation={true}
                      sx={buttonSx}
                      >
                      DONATE
                      </Button> 
                      </a>

                  </Stack>
            </Grid>
            <Grid container item sx={{width: {xs: "16.67vw", md: 160}, justifyContent: "center"}}>
                    <Stack spacing={{xs:0}} sx={stackSx}>
                          <Grid item sx={{fontSize: {xs: "1.666vw", md: 16}}}>TRANSLATOR</Grid> 
                          <Grid item sx={logoSx}> 

                          {( translator !== "not assigned" ) ?
                          
                          <RouterLink to={"/translators/" + translator.toLowerCase()} 
                          onClick={(e) => {e.preventDefault(); navigate("/translators/" + translator.toLowerCase())}}>
                              <LazyLoadImage
                              effect="blur"
                              placeholderSrc={"../translators/" + translator + "_logo150.png"}
                              src={"../translators/" + translator + "_logo150.png"}
                              // height="100%"
                              width="100%"
                              style={{objectFit: "cover"}}
                              />
                          </RouterLink>
                          :
                            <LazyLoadImage
                              effect="blur"
                              placeholderSrc={"../images/question.png"}
                              src={"../images/question.png"}
                              // height="100%"
                              width="100%"
                              style={{objectFit: "cover"}}
                              />
                          }
                          </Grid> 
                      
                  </Stack>
            </Grid>

      </Grid>
    )
}

export default ActiveDon