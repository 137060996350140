import { Grid, Box } from "@mui/material"
import Navbar from '../components/navbar/Navbar';
import SingleMovie from "../components/movie/SingleMovie"

const Movie = () => {

    return (
            <>
                <Navbar />
                <Box sx={{textAlign: "center"}}>
                    <Grid container item sm={12} sx={{m: "0 auto", width: {xs: "100%"}}}>

                        <SingleMovie />

                    </Grid>
                </Box>
            </>

    );
}

export default Movie;
