import Navbar from '../components/navbar/Navbar';
import { Typography } from "@mui/material"
import SingleAw from '../components/singleaw/SingleAw';

const Award = () => {

    return (
            <Typography component="span" variant="body1"> 
                <Navbar />
                <SingleAw />
            </Typography>
    )
}

export default Award;
