import { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { Grid, Stack, Link } from '@mui/material';
import { transLogoSx, transMenuSx } from "./trStyle.js";
import { transConSx } from "../../theme";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios"
import Sorter from "./sorter/Sorter"

const TrMd = () => {

    const navigate = useNavigate()

    const location = useLocation()
    const path = location.pathname.split("/")[2]
    const params = location
    // console.log(path)

    const [tr, setTr] = useState([])

    useEffect(() => {
            const getBase = async () => {
                const res = await axios.get("/api/translators/" + path, {
                    responseType: 'json',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                    })
                setTr(res.data)
            }
            getBase()
        }, [])

    const [coonbase, setCoonbase] = useState([])

    useEffect(() => {
            const getBase = async () => {
                const res = await axios.post(`/api/coon/tr/${path}`, params, {
                    responseType: 'json',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    }
                  })
                setCoonbase(res.data)
            }
            getBase()
        }, [params])

    return (

        <Grid container item sm={12} sx={{m: "0 auto 10px", width: {xs: "100%", sm: 960}}}>
                <Grid container sx={{...transConSx}}>
                    <Grid container item sx={{...transLogoSx}}>
                        <LazyLoadImage
                        height="100%"
                        width="100%"
                        effect="blur"
                        placeholderSrc={"../translators/" + tr.name_exp + "_logo150.png"}
                        src={"../translators/" + tr.name_exp + "_logo150.png"}
                        />
                    </Grid>
                    <Grid container item sx={{...transMenuSx, ml: "5px"}}>
                        <Stack spacing={0} sx={{justifyContent: "space-evenly", width: "100%", height: "100%", textAlign: "left"}}>
                            <Grid sx={{fontSize: 32}}>{tr.name}</Grid>
                            <Grid sx={{fontSize: {xs: "1.8vw", md: 15}, fontWeight: 500, color: "info.dark"}}>{"Some " + tr.name + " info"}</Grid>
                            <Grid container sx={{ textTransform: "uppercase"}}>
                                <Grid container item xs={4}>
                                    <Grid item xs={7} sx={{display: "flex", alignItems: "center"}}>Subbed</Grid>
                                    <Grid item xs={5} sx={{textTransform: "none", fontSize: {xs: "3.8vw", md: 32}, fontWeight: 700}}>{coonbase.length}</Grid>
                                </Grid>
                                <Grid container item xs={4}>
                                    <Grid item xs={7} sx={{display: "flex", alignItems: "center"}}>Support</Grid>
                                    <Grid item xs={5} sx={{display: "flex", alignItems: "center"}}>
                                        <Link className='left' href={tr.support} target="_blank" sx={{height: {xs: "3.5vw", md: 35}, ":hover": {opacity: "0.7", transform: "scale(1.2)"}}}>
                                        <img alt="" height="100%" src="../images/piggybank_gold.png"/></Link>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={4}>
                                    <Grid item xs={7} sx={{display: "flex", alignItems: "center"}}>Contact</Grid>
                                    <Grid item xs={5} sx={{display: "flex", alignItems: "center"}}>
                                        <Link className='left' href={tr.contact} target="_blank" sx={{height: {xs: "3.5vw", md: 35}, ":hover": {opacity: "0.7", transform: "scale(1.2)"}}}>
                                        <img alt="" height="100%" src="../logo/telegram_logo.png"/></Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Sorter location={location}/>
                    <Grid container item xs sx={{height: {xs: "16.66vw", md: 153}, m: " auto"}}>
                    {coonbase.length > 0 && coonbase.map(m => {

                        const ids = JSON.parse(m.ids)
                        const main = JSON.parse(m.maininfo)
                        const release = JSON.parse(m.releaseinfo)
                        const posters = JSON.parse(m.posters)

                        return (
                        <Grid key={ids.imdb_id}  container item sx={{height: "100%", width: {xs: "10.5vw", md: 106.6}}}>
                            <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                <LazyLoadImage
                                height="100%"
                                width="100%"
                                effect="blur"
                                title={main.imdb_title_exp + " (" + release.year + ")"}
                                style={{objectFit: "cover"}}
                                placeholderSrc={'../posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                src={'../posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                />
                            </RouterLink>
                        </Grid>
                    )})}
                    </Grid>
                
                </Grid>
        </Grid>
    )}

export default TrMd;
