import { Grid, Link } from "@mui/material"
import {onlLogoSx, coonSx} from "../dirStyle"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Streaming = ({m}) => {

    const streamings = JSON.parse(m.streaming)
    // console.log(streamings)

    return (
        <Grid item sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {streamings.map((str) => {
                return(
                    <Link className='onllogo' key={str.id} href={str.url + str.link} target="_blank" rel="noopener noreferrer"> 
                    <Grid item sx={onlLogoSx}>
                        <LazyLoadImage
                          className="stream"
                          effect="blur"
                          delayTime={100}
                          threshold={50}
                          placeholderSrc={"../logo/" + str.service + "_logo.png"}
                          src={"../logo/" + str.service + "_logo.png"}
                          height="100%"
                          width="100%"
                          style={{objectFit: "cover"}}
                          />
                        </Grid>
                    </Link>
                )
            })}
            {streamings.length !== 0 ? null : 
            <Grid 
                component="img" src={"../images/sad-coon_white_glasses_q60.png"} sx={coonSx}>
            </Grid>} 
        </Grid>
    );
}

export default Streaming;
