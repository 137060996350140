import { LazyLoadImage } from 'react-lazy-load-image-component';
import { InfoWrap, OrigTitle, PosterWrap, Title, Tag, TitleBlock, Content, InfoRow } from '../movieStyle';
import { Grid } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Info = ({movie}) => {

    const main = JSON.parse(movie.maininfo)
    const release = JSON.parse(movie.releaseinfo)
    const posterPath = '../posters/'
    const posters = JSON.parse(movie.posters)
    const allp = JSON.parse(movie.allposters)
    const start = posters.coon_default
    allp.sort((a,b) => Math.abs(a.index - start) - Math.abs(b.index - start))
    // console.log(allp)

    let origTitle = ""

    if (main.imdb_title !== main.imdb_title_exp) {
        origTitle = main.imdb_title
    }

    return (
        <Grid container sx={{borderBottom: "1px solid #1E2D35"}}>
            
            <PosterWrap>
            {allp.length > 0 && 
            <Carousel
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            infiniteLoop={true}>
                {allp.map(p => 
                    <LazyLoadImage
                    key={p.index}
                    effect="blur"
                    placeholderSrc={posterPath + p.name}
                    src={posterPath + p.name}
                    height="100%"
                    width="100%"
                    style={{objectFit: "cover"}}
                    />
                )}
            </Carousel>
            }
            </PosterWrap>
            
            <InfoWrap>
                <TitleBlock>
                    <Title>{main.imdb_title_exp}</Title>
                    <OrigTitle>{origTitle}</OrigTitle>
                </TitleBlock>
    
                <InfoRow><Tag>year:</Tag><Content>{release.year}</Content></InfoRow>
                <InfoRow><Tag>runtime:</Tag><Content>{main.imdb_duration}</Content></InfoRow>
                <InfoRow><Tag>country:</Tag><Content>{main.imdb_country}</Content></InfoRow>
                <InfoRow><Tag>genre:</Tag><Content>{main.imdb_genre}</Content></InfoRow>
                <InfoRow><Tag>director:</Tag><Content>{main.imdb_director}</Content></InfoRow>
                <InfoRow><Tag>starring:</Tag><Content>{main.imdb_stars}</Content></InfoRow>
                <InfoRow><Tag>summary:</Tag><Content>{main.imdb_description}</Content></InfoRow>

            </InfoWrap>
        </Grid>
    );
}

export default Info;
