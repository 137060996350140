const selectSx = {
    "& .MuiSvgIcon-root": {
        color: "silver",
      },
      color: "primary.main",
      padding: {xs: "0 8px 0 6px", md: "6px 12px 6px 12px"},
      "& fieldset": {border: "silver"},
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        border: "2px solid",
        borderColor: 'silver'},
      ':hover': {
        "& fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: 'silver'}
      },
      '&.Mui-focused': {
        "& fieldset.MuiOutlinedInput-notchedOutline": {
        border: "2px solid",
        borderColor: 'silver'}
      },
      "& .MuiOutlinedInput-input": {
        fontSize: {xs: 11, md: 14}, 
        padding: 0}
}

const genreItemSx = {
    fontSize: {xs: 12, md: 14},
    minHeight: "auto",
    padding: "4px 36px",
    ":hover": {
        color: "primary.main", 
        backgroundColor: "info.dark"},
    "&.Mui-selected": {
        color: "primary.main", 
        backgroundColor: "info.dark",
        ":hover": {
            backgroundColor: "info.main",
            color: "info.dark"
        }
    }
}

export { selectSx, genreItemSx }
