import React from "react";
import { BrowserRouter } from "react-router-dom"
import { AppRoutes } from "./routes"
import { ContextProvider } from "./context/AuthContext"
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { theme} from "./theme";
import "./App.css"

function App() {

  return (
    <ContextProvider>
        <ThemeProvider className="themeProvider" theme={theme}>
            <CssBaseline >
            <div>
              <BrowserRouter>
                  <AppRoutes />
              </BrowserRouter>
            </div>
            </CssBaseline>
        </ThemeProvider>
    </ContextProvider>
    )
}

export default App;
