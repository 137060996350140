const transLogoSx = {
    height: {xs: "20vw", sm: "16.66vw", md: 150},
    width: {xs: "20vw", sm: "16.66vw", md: 150}
}

const transMenuSx = {
    padding: {xs: "0 10px", sm: "0 3.5vw", md: "0 30px"},
    bgcolor: "rgba(237, 230, 219, 0.06)",
    width: {xs: "80vw", sm: "82vw", md: 805},
    fontSize: {xs: "2vw", md: 17},
    fontWeight: 700,
    alignItems: "center"
}

export { transLogoSx, transMenuSx}