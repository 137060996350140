import { Grid, Link, Button } from '@mui/material';
import { useState, useEffect } from "react"
import { useNavigate, Link as RouterLink } from "react-router-dom";
import axios from "axios"
import "./progress.css"
import { StreamWrap, donateSx, buttonSx, linkSx, donBlocks, Numbers } from "../movieStyle";

const Donate = ({movie}) => {

  const navigate = useNavigate()

  const wallet = movie.wallet_id
  const translator = JSON.parse(movie.donations).pot_tr

  const walletUrl = 'https://yoomoney.ru/fundraise/' + wallet

  const [fund, setFund] = useState({
    percent: 0
  })

  useEffect(() => {
    const getFund = async () => {
        try {
            const res = await axios.post(`/api/donations/${movie.imdb_id}`)

            console.log(res.data)
            const regex = /\D/g
            const percent = res.data[0].percent

            let cur_sum, full_sum
            res.data[0].d_sum.includes('from') ? 
            cur_sum = res.data[0].d_sum.split(' ₽ from ')[0].replace(' ', '').replace(',', '.') :
            cur_sum = res.data[0].d_sum.split(' ₽ out of ')[0].replace(' ', '').replace(',', '.')
            // console.log(cur_sum)
            // .replace(regex, '')
            res.data[0].d_sum.includes('from') ? 
            full_sum = res.data[0].d_sum.split(' ₽ from ')[1].replace(regex, '') :
            full_sum = res.data[0].d_sum.split(' ₽ out of ')[1].replace(regex, '')
            // console.log(percent)

            setFund({
              cur_sum: cur_sum,
              full_sum: full_sum,
              percent: percent
            })

        }
        catch(error){
            console.error(error)
        }
    }
    getFund()
}, [])

    
    console.log(fund)
    const tr = translator.replace(/\s/g, "_")

    return (
      <Grid container sx={donateSx}>
          <Grid sx={{width: "100%", mb: {xs: "10px", sm: "20px"}}}>
              <StreamWrap>
                    <Link className='left' href={walletUrl} sx={linkSx} targer='blank' rel='noopener noreferrer'>
                    <Button
                    type="submit"
                    variant="contained"
                    disableElevation={true}
                    sx={buttonSx}
                    >
                    DONATE FOR RUSUBS
                    </Button> 
                    </Link>
              </StreamWrap>
          </Grid>
          <Grid sx={{width: "100%", textAlign: 'left'}}>
              <progress id="fund" max={fund.full_sum} value={fund.cur_sum}>
              </progress>
          </Grid>
          <Grid sx={donBlocks}>
              <div style={{width:"50%", color: "silver"}}>raised:<Numbers>{fund.cur_sum}</Numbers></div>
              <div style={{width:"50%", textAlign: "right", color: "silver"}}>goal:<Numbers>{fund.full_sum}</Numbers></div>
          </Grid>
          <Grid sx={donBlocks}>
              <div style={{color: "silver"}}>translator:</div>
              <div>
                <Numbers>

                {( translator !== "not assigned" ) ?

                    <RouterLink className='sublogo left' to={"/translators/" + tr.toLowerCase()} 
                    onClick={(e) => {e.preventDefault(); navigate("/translators/" + tr.toLowerCase())}}>
                    {translator}
                    </RouterLink>
                    :
                    "not assigned"
                }

                </Numbers>
              
              </div>
          </Grid>
      </Grid>

    );
}

export default Donate;
