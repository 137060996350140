import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { Grid } from '@mui/material';
import { transConSx, textureSx } from "../../theme";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios"
import _debounce from "lodash.debounce"
import Sorter from "./Sorter.tsx"

const Sub = () => {

  const navigate = useNavigate()

  const location = useLocation()
  // console.log(location)
  const [filters, setFilters] = useState('')

  const [coonbase, setCoonbase] = useState([])
  const [rCount, setRCount] = useState(0)
  const [limit, setLimit] = useState(99)

  // ---------------------- SEARCH -----------------------------

  const [inputText, setInputText] = useState("");
  function slowHandler (e) {
  var lowerCase = e.target.value.toLowerCase();
  setInputText(lowerCase);
  };

  const inputHandler = useCallback(_debounce(slowHandler, 500), [])

  const [fusefilt, setFusefilt] = useState({
      var: 'Title'
  });

  const varCheck = (curcat) => {
      setFusefilt({
          ...fusefilt,
          var: curcat
      })
  }

  const params = {loc: location, lim: limit, input: inputText, filt: fusefilt}



  useEffect(() => {
            const getBase = async () => {
              const res = await axios.post(`/api/coon/allsubs`, params, {
                  responseType: 'json',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                  }
                })
              // console.log(res)
              setCoonbase(res.data.cut)
              setRCount(res.data.length)
          }
          getBase()
      }, [location, fusefilt, limit, inputText])


  const result = coonbase

      const mainSx = {
        padding: "14px", 
        m: "9px auto", 
        justifyContent: 'center',
        color: "primary.main", 
        width: {xs: "100%", md: 960},
        fontSize: {xs: "1.875vw", md: 16},
        bgcolor: "rgba(237, 230, 219, 0.06)",
        ':hover': {
          color: "#bdd4d2",
          bgcolor: "rgba(237, 230, 219, 0.12)"
        }
        // bgcolor: "rgba(236, 239, 241, 0.95)",
    }

    return (

        <Grid container item sm={12} sx={{m: "0 auto 10px", width: {xs: "100%", sm: 960}}}>
                <Grid container sx={{...transConSx}}>
                    <Sorter varCheck={varCheck} inputHandler={inputHandler} location={location} 
                        setLimit={setLimit} filters={filters} setFilters={setFilters} rCount={rCount}/>
                    <Grid container item xs sx={{height: {xs: "27.5vw"}, m: " auto"}}>
                        {result.length > 0 && result.map(r => {

                            let m
                            r.item ? m = r.item : m = r

                            const ids = JSON.parse(m.ids)
                            const main = m.imdb_title_exp
                            const release = JSON.parse(m.releaseinfo)
                            const posters = JSON.parse(m.posters)

                            return (
                                <Grid key={ids.imdb_id} item sx={{height: "100%", width: "20vw", border: "solid", borderWidth: "1vw", borderColor: "transparent", ":hover": {opacity: "0.9", borderColor: "white"}}}>
                                    <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                        <LazyLoadImage
                                        height="100%"
                                        width="100%"
                                        effect="blur"
                                        title={main.imdb_title_exp + " (" + release.year + ")"}
                                        style={{objectFit: "cover"}}
                                        placeholderSrc={'../posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                        src={'../posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}                            
                                        />
                                    </RouterLink>
                                </Grid>
                        )})}
                        {limit <= result.length && 
                        <Grid 
                        container 
                        onClick={() => setLimit(limit + 99)}
                        sx={{...mainSx, ...textureSx, cursor: 'pointer'}}>
                        see more
                        </Grid>}
                    </Grid>
                
                </Grid>
        </Grid>
    )}

export default Sub;
