import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { headlineSx, donconSx } from "./donStyle";
import ActiveDon from "./ActiveDonPg"
import FinishDon from "./FinishDon"
import axios from 'axios'

const Don = () => {

    const [coonbase, setCoonbase] = useState([])

    useEffect(() => {
            const getBase = async () => {
                try {
                    const res = await axios.get("/api/coon/homesd")
                    const log = {type: 'info', text: `${res.data.don.length} movies received from backend`}
                    await axios.post("/api/clientlogs", log)
                    setCoonbase(res.data.don)
                    // console.log(Date.now() - res.data.startTime)
                } catch (err) {
                    console.log(err)
                    const log = {type: 'error', text: `${err}`}
                    await axios.post("/api/clientlogs", log)
            }
            }
            getBase()
        }, [])

        // console.log(coonbase)

    const base = [...new Set([...coonbase.filter(m => JSON.parse(m.donations).wallet_id !== null)])]
    const donated = [...new Set([...base.map(m => {
        return (
            {...m, 
            imdb_id: JSON.parse(m.ids).imdb_id,
            donations: JSON.parse(m.donations),
            finish_date: new Date(JSON.parse(m.donations).finish_date)
            })
    })])]

    const active = [...new Set(donated.filter((movie) => movie.donations.wallet_id !== '' && Number(movie.donations.percent) < 100))]

    active.sort((a, b) => b.donations.percent - a.donations.percent)

    const completed = [...new Set(donated.filter((movie) => Number(movie.donations.percent) >= 100))]

    completed.sort((a, b) => b.finish_date - a.finish_date)

    const [tr, setTr] = useState([])

    useEffect(() => {
            const getBase = async () => {
                try {
                    const res = await axios.get("/api/translators/")
                    const log = {type: 'info', text: `${res.data.length} translators received from backend`}
                    await axios.post("/api/clientlogs", log)
                    setTr(res.data)
                } catch (err) {
                    console.log(err)
                    const log = {type: 'error', text: `${err}`}
                    await axios.post("/api/clientlogs", log)
            }
            }
            getBase()
        }, [])

    // console.log(donated)

    const posterPath = "../posters/"

    return (
        <Grid sx={donconSx}>
            <Grid container sx={headlineSx}>
                    <div style={{margin: "auto 50px"}}>ACTIVE</div>
            </Grid>
            <Grid container >

            {tr.length > 0 && active.map(movie => {

                return (
                <ActiveDon key={movie.imdb_id} movie={movie} posterPath={posterPath} tr={tr} imdb_id={movie.imdb_id}/>
                )
                })}

            </Grid>
            <Grid container sx={headlineSx}>
                    <div style={{margin: "auto 50px"}}>COMPLETED</div>
            </Grid>
            <Grid container >

            {tr.length > 0 && completed.map(movie => {

                return (
                <FinishDon key={movie.imdb_id} movie={movie} posterPath={posterPath} tr={tr} />
                )
                })}

            </Grid>
        </Grid>

    )
}

export default Don;
