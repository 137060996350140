const donconSx = {
    width: {xs: "100%", md: 960}, 
    m: "10px auto", 
    color: "primary.main"
}

const headlineSx = {
    height: {xs: "7.5vw", md: 48}, 
    maxHeight: 48,
    m: "0 0 10px", 
    bgcolor: "rgba(237, 230, 219, 0.12)",
    fontSize: {xs: "2vw", md: 18},
    fontWeight: 600
}

const movieSx = {
    height: {xs: "21.04vw", md: 202},
    maxHeight: 202,
    m: "0 0 10px", 
    bgcolor: "rgba(237, 230, 219, 0.06)"
}

const posterSx = {
    position: "relative",
    height: {xs: "21.04vw", md: 202}, 
    maxHeight: 202,
    width: {xs: "14.17vw", md: 136}, 
    mr: "10px", 
    // imageRendering: "high-quality",
    transition: 0, 
    // "&:hover": {
    //     zIndex: 100,
        // boxShadow: {xs: "none", sm: "0 0 2px 2px white"}, 
        // transform: {xs: "scale(1.5) translate(12px, 0)", sm: "scale(1.75}" md: "scale(2)"},
    //     transform: {xs: "scale(1.8) translate(12px, 0)", md: "scale(1.8)"},
    //     transition: "0.2s"
    // }
}

const vertextSx = {
    width: {xs: "5vw", md: 60},
    writingMode: "vertical-rl",
    textOrientation: "upright"
    }

const stackSx = {
    padding: {xs: "2px 0", sm: "4px 0", lg: "6px 0"}, 
    height: "100%", 
    textAlugh: "center", 
    justifyContent: "space-between",
    alignItems: "center"
}

const percentSx = {
        fontSize: {xs: "1.875vw", md: 18},
        width: {xs: "9.5vw", md: 100},
        height: {xs: "9.5vw", md: 100}
    }

const boxSx = {
    position: 'relative', 
    display: 'inline-flex', 
    width: "100%", 
    height: "100%", 
    justifyContent: "center",
    alignItems: "center"
}

const circleSx = {
    position: "absolute",
    color: "rgba(255,255,255,0.1)"
    }

const buttonSx = {
        width: { xs: "12.5vw", md: 120},
        minWidth: 0,
        padding: 0,
        alignSelf: "center",
        borderRadius: "15px",
        color: "secondary.main", 
        backgroundColor: "primary.main", 
        fontSize: { xs: "1.8vw", md: 17 },
        fontWeight: 700,
        ":hover": {
           color: "primary.main", 
           backgroundColor: "#90A4AE"},
        m: 0,
        // ":disabled": {bgcolor: "rgba(255,255,255,0.4)", color: "#90A4AE"}
      }

const logoSx = {
    width: {xs: "11vw", md: 100}, 
    height: "75%",
    m: "auto", 
    padding: {xs: "5px 0", lg: "6px 0 15px"}
}

export { headlineSx, movieSx, posterSx, vertextSx, percentSx, buttonSx, circleSx, stackSx, boxSx, logoSx, donconSx }