import { Box } from '@mui/material';
import Navbar from '../components/navbar/Navbar';
import useWindowDimensions from "./../functions/windowDimensions"
import TrMd from '../components/singletr/TrMD';
import TrXs from '../components/singletr/TrXS';

const SingleTr = () => {

    const { clientWidth } = useWindowDimensions();


    return (
        <>
                <Navbar />
                <Box sx={{textAlign: "center"}}>

                    {(clientWidth > 600) ? 

                    <TrMd />

                    :

                    <TrXs />
                    
                    }
                </Box>
         </>
    )
}

export default SingleTr;


