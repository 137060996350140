import { styled } from '@mui/material/styles';

 // STYLING 

 const menuPosterSx = {
    minHeight: {xs: "23vw", md: 221},
    // m: "20px 0",  
    m: 0,
    justifyContent: "center", 
    alignItems: "flex-end", 
    color: "primary.main", 
    // boxShadow: "0 0 10px 5px white"
}

const coonMenuSx = {
    m: "0 auto",
    fontSize: {xs: "2.6vw", sm: "2.3vw", md: 21},
    height: {xs: "9.5vw", md: 64},
    maxHeight: 64,
    width: 960,
    lineHeight: 1.3,
    fontWeight: 700,
    textTransform: "uppercase"

}

const coonMenuItemSx = {
    display: "flex",
    padding: "5px",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    ":hover": {
        color: "primary.main", 
        backgroundColor: "info.dark"},
}

const menuLinkSx = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "info.main", 
    ":hover": {color: "primary.main"}
}

const coonNewsSx = {
    color: "primary.main",
    justifyContent: "right",
    width: {xs: "100%", md: 960}, 
    height: "auto", 
    m: "10px auto",
    bgcolor: "rgba(237, 230, 219, 0.06)",
    paddingRight: {xs: "2vw", md: "18px"}
}


const Newshead = styled('h2')(({ theme }) => ({
        fontSize: "3.3vw",
        marginBottom: 0,
        [theme.breakpoints.up('sm')]: {
            fontSize: 17,
            },
        [theme.breakpoints.up('md')]: {
        fontSize: 21,
      },
    }))

const Newsdate = styled('p')(({ theme }) => ({
    fontSize: "2.2vw",
    marginBottom: 0,
    color: "silver",
    [theme.breakpoints.up('sm')]: {
        fontSize: 11,
        },
    [theme.breakpoints.up('md')]: {
    fontSize: 13
    },
}))

const Newstext = styled('div')(({ theme }) => ({
    fontSize: "2.6vw",
    marginLeft: "calc(35px + 4.6vw)",
    [theme.breakpoints.up('sm')]: {
        fontSize: 13,
        marginLeft: "11.3vw"
        },
    [theme.breakpoints.up('md')]: {
    fontSize: 16,
    marginLeft: "105px"
    },
}))

const headlineSx = {
    color: "primary.main",
    width: {xs: "100%", md: 960}, 
    height: {xs: "7.5vw", md: 48}, 
    maxHeight: 48,
    m: "10px auto",
    bgcolor: "rgba(237, 230, 219, 0.06)",
    // padding: {xs: "2vw", md: "10px 20px"},
    fontSize: {xs: "2vw", md: 18},
    fontWeight: 600
}

const searchCon = {
    m: "0 auto 10px", 
    width: {xs: "100%", md: 960},
    height: {xs: "6.5vw", md: 40}, 
    maxHeight: 40
}

export { menuPosterSx, coonMenuSx, coonMenuItemSx, menuLinkSx, coonNewsSx, Newshead, Newsdate, Newstext, headlineSx, searchCon}