import Navbar from '../components/navbar/Navbar';
import { Box, Grid, Typography } from "@mui/material"
import AllAwards from '../components/awards/AllAwards';

const Awards = () => {

    const mainSx = {
        padding: "0", 
        m: "9px auto 0", 
        color: "primary.main", 
        width: {xs: "100%", md: 960},
        // bgcolor: "rgba(237, 230, 219, 0.06)",
    }

    return (
            <Typography component="span" variant="h2"> 
                <Navbar />
                <Box sx={{textAlign: "center"}}>
                    <Grid container item sm={12} sx={mainSx}>

                        <Typography component="span" variant="body1" sx={{m: "0 auto", width: {xs: "100%"}}}>
                                <AllAwards />
                        </Typography>

                    </Grid>
                </Box>
            </Typography>
    )
}

export default Awards;
