import { Grid, Stack } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { FlagWrap, dirConSx, titleSx, dirLogoSx, dirMenuSx  } from './dirSx';

const DirMd = ({coonbase, dir}) => {

    const navigate = useNavigate()

    return (

        <Grid container item sm={12} sx={{m: "0 auto 10px", width: {xs: "100%", sm: 960}}}>
            {dir.map(d => {

                const directed = [...new Set([...coonbase.filter(m => JSON.parse(m.directors).array_agg.includes(d.imdb_id))])]

                const subbed = [...new Set([...directed.filter(d => d.rusub === 'rus')])]
                
                const logo = d.cin_id + ".jpg"
                const nations = d.nation.replaceAll(' ', '_').split(',_')

                return (
                <Grid key={d.imdb_id} container sx={dirConSx}>
                <Grid container item sx={dirLogoSx}>
                    <div id={d.cin_id}>
                    <RouterLink className="dirlogo" to={"/directors/" + d.cin_id} onClick={(e) => {e.preventDefault(); navigate("/directors/" + d.cin_id)}}>
                    <LazyLoadImage
                    height="100%"
                    width="100%"
                    effect="blur"
                    placeholderSrc={"../directors/" + logo}
                    src={"../directors/" + logo}
                    />
                    </RouterLink>
                    </div>
                </Grid>
                <Grid container item sx={{...dirMenuSx, ml: "5px"}}>
                    <Stack spacing={0} sx={{justifyContent: "space-evenly", textAlign: "left", height: "100%"}}>
                        <Grid item sx={titleSx}>
                            <RouterLink className='left' to={"/directors/" + d.cin_id} onClick={(e) => {e.preventDefault(); navigate("/directors/" + d.cin_id)}}>
                                {d.name}
                            </RouterLink>
                        </Grid>
                        {d.death_year && <p>{d.birth_year + " - " + d.death_year}</p>}
                        {(!d.death_year && d.birth_year) && <p>{d.birth_year}</p>}
                        <Grid sx={{display: 'flex'}}>
                        {nations.map(n => {
                            return (
                                <Grid key={n} sx={{mr: {xs: '1vw', sm: '0.8vw', md: '7px'}, height: {xs: '3vw', sm: '2.7vw', md: '24px'}}}>
                                    <FlagWrap>
                                        <LazyLoadImage
                                            height="100%"
                                            effect="blur"
                                            placeholderSrc={"../flags/" + n.toLowerCase() + ".jpg"}
                                            src={"../flags/" + n.toLowerCase() + ".jpg"}
                                            />
                                    </FlagWrap>
                                </Grid>
                            )
                        }
                        )}
                        </Grid>
                        <Grid sx={{display: 'flex'}}><Grid sx={{mr: '10px'}}>Movies: {directed.length}</Grid><Grid>Subbed: {subbed.length}</Grid></Grid>
                    </Stack>
                </Grid>
                <Grid container item xs sx={{height: {xs: "16.66vw", md: 153}, m: {xs: "0 auto 0 0.5vw", sm: "0 auto 0 5px"}}}>
                {directed.slice(0, 5).map(m => {

                    const ids = JSON.parse(m.ids)
                    const posters = JSON.parse(m.posters)

                    return (
                    <Grid key={ids.imdb_id}  container item sx={{height: "100%", width: {xs: "11.4vw", md: 106}}}>
                        <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} 
                            onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                            <LazyLoadImage
                            height="100%"
                            width="100%"
                            effect="blur"
                            style={{objectFit: "cover"}}
                            placeholderSrc={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                            src={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                            />
                        </RouterLink>
                    </Grid>
                )})}
                </Grid>
                
            </Grid>
                )}
            )}
        </Grid>
    )}

export default DirMd;
