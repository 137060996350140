import { Grid, Stack } from "@mui/material"
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { coonMenuSx, coonMenuItemSx } from "./menuStyle"

const MenuBlock = () => {

    const navigate = useNavigate()

    // const elements = [
    //     {href: 'years', name: 'Movies'},
    //     {href: '', name: 'Movies'},
    //     {href: '', name: 'Movies'},
    //     {href: 'years', name: 'Movies'},
    // ]

    return (
        <Grid container sx={{bgcolor: "rgba(255,255,255,0.12)"}}>
        <Grid container sx={{...coonMenuSx}}>
            <Stack direction="row" spacing={{xs: 1, sm: 2}} sx={{width: "100%", height: "100%"}}>
                <Grid item xs={3} sx={{...coonMenuItemSx}}>
                    <RouterLink className="center" to="/years" onClick={(e) => {e.preventDefault(); navigate("years")}}>
                        Movies
                    </RouterLink>
                </Grid>
                <Grid item xs={3} sx={{...coonMenuItemSx}}>
                    <RouterLink className="center" to="/directors" onClick={(e) => {e.preventDefault(); navigate("directors")}}>
                        Directors
                    </RouterLink>
                </Grid>
                <Grid item xs={3} sx={{...coonMenuItemSx}}>
                    <RouterLink className="center" to="/events" onClick={(e) => {e.preventDefault(); navigate("events")}}>
                        Awards & Fests
                    </RouterLink>
                </Grid>
                <Grid item xs={3} sx={{...coonMenuItemSx}}>
                    <RouterLink className="center" to="/translators" onClick={(e) => {e.preventDefault(); navigate("translators")}}>
                        Translators
                    </RouterLink>
                </Grid>
            </Stack>
        </Grid>
        </Grid>
    );
}

export default MenuBlock;
