import { Box } from '@mui/material';
import Navbar from '../components/navbar/Navbar';
import Don from '../components/donations/Don';

const Donations = () => {

    return (
        <>
                <Navbar />
                <Box sx={{textAlign: "center"}}>

                    <Don />

                </Box>
         </>
    )
}

export default Donations;


