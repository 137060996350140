import { Box } from '@mui/material';
import Navbar from '../components/navbar/Navbar';
import useWindowDimensions from "./../functions/windowDimensions"
import TranMd from '../components/translators/TranMd';
import TranXs from '../components/translators/TranXs';

const Translators = () => {

    const { clientWidth } = useWindowDimensions();

    return (
        <>
                <Navbar />
                <Box sx={{textAlign: "center"}}>

                    {(clientWidth > 600) ? 

                    <TranMd />

                    :

                    <TranXs />
                    
                    }
                </Box>
         </>
    )
}

export default Translators;


