import { styled } from '@mui/material/styles';

 // STYLING 

 const awLogoSx = {
  height: {xs: "15vw", sm: "13.33vw", md: 120},
  width: {xs: "15vw", sm: "13.33vw", md: 120}
}

const titleSx = {
    fontSize: { xs: "2.1vw", md: 20 }, 
    fontWeight: 700,
    lineHeight: 1.2,
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
}

const headlineSx = {
    color: "primary.main",
    width: {xs: "100%", md: 960}, 
    height: {xs: "7.5vw", md: 48}, 
    maxHeight: 48,
    m: "10px auto",
    bgcolor: "rgba(237, 230, 219, 0.06)",
    // padding: {xs: "2vw", md: "10px 20px"},
    fontSize: {xs: "2vw", md: 18},
    fontWeight: 600
}

const awCon = {
    // m: "0 auto 10px", 
    // width: {xs: "100%", md: 960},
    height: {xs: "6.5vw", md: 150}, 
    // maxHeight: 40
}

export { awLogoSx, titleSx, headlineSx, awCon}