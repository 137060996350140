import { Grid, Stack } from '@mui/material';
import { useNavigate, Link as RouterLink } from "react-router-dom";

const Description = ({ids, main, release}) => {

    const navigate = useNavigate()

    const stars = main.imdb_stars.split(', ').slice(0, 7).join(', ')
    // console.log(stars)

    const descriptionSx = {
        color: "secondary.main",
        bgcolor: "rgba(255,255,255,0.9)",
        // bgcolor: "rgba(31, 46, 54, 0.95)",
        position: "absolute",
        height: "25vw",
        width: "60vw",
        m: "-25vw 0 0 16.8vw",
        padding: "5px 7px"
    }

    const desTitleSx = {
        fontSize: "2.3vw", 
        lineHeight: 1.3,
        fontWeight: 700, 
        paddingBottom: "0.8vw"
    }

    const desItemSx = {
        fontSize: "1.7vw", 
        lineHeight: 1.3,
        paddingBottom: "5px",
        overflow: "hidden",
        // display: "-webkit-box",
        // WebkitLineClamp: 1,
        // WebkitBoxOrient: "vertical"
    }

    return (
        <Grid container item>
                <Stack spacing={0} sx={descriptionSx}>
                    <Grid item sx={desTitleSx}>
                        <RouterLink className='left desc' to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>{main.imdb_title_exp}</RouterLink>
                    </Grid>                    
                    <Grid item sx={desItemSx}>{release.year} | {main.imdb_duration} | {main.imdb_country}</Grid> 
                    <Grid item sx={{...desItemSx, WebkitLineClamp: 2}}><b>Starring:</b> {stars} </Grid>
                    <Grid item sx={{...desItemSx, WebkitLineClamp: 4}}><b>Summary:</b> {main.imdb_description} </Grid>
                    {/* {(typeof props.trans === "undefined") ? null : <Grid item sx={{...desItemSx}}><b>Translated by:</b> {props.trans}</Grid>} */}
                </Stack>
        </Grid>
    )
}

export default Description;
