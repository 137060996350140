import { Grid, Stack, Link } from '@mui/material';
import { useState, useEffect } from "react"
import { transConSx, transLogoSx, transMenuSx } from "../../theme";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios"
import { useNavigate, Link as RouterLink } from "react-router-dom";

const TranMd = () => {

    const navigate = useNavigate()

    const [coonbase, setCoonbase] = useState([])

    useEffect(() => {
            const getBase = async () => {
                try {
                    const res = await axios.get("/api/coon/")
                    const log = {type: 'info', text: `${res.data.full.length} movies received from backend`}
                    await axios.post("/api/clientlogs", log)
                    setCoonbase(res.data.full)
                } catch (err) {
                    console.log(err)
                    const log = {type: 'error', text: `${err}`}
                    await axios.post("/api/clientlogs", log)
            }
            }
            getBase()
        }, [])

    const [tr, setTr] = useState([])

    useEffect(() => {
            const getBase = async () => {
                try {
                    const res = await axios.get("/api/translators/")
                    const log = {type: 'info', text: `${res.data.length} translators received from backend`}
                    await axios.post("/api/clientlogs", log)
                    setTr(res.data)
                } catch (err) {
                    console.log(err)
                    const log = {type: 'error', text: `${err}`}
                    await axios.post("/api/clientlogs", log)
            }
            }
            getBase()
        }, [])

    const base = [...new Set([...coonbase.filter(m => JSON.parse(m.subtitles).translators !== null)])]
    const subbed = [...new Set([...base.map(m => {
        return ({
            ...m, 
            sub_date: new Date(JSON.parse(m.subtitles).sub_date),
            sub_add: new Date(JSON.parse(m.subtitles).sub_add),
            translators: JSON.parse(m.subtitles).translators
        })
    })])]
    
    const translators = [...new Set([...tr.map(t => {
        return ({
            ...t, 
            sub_date: new Date(t.last_sub),
        })
    })])]
    translators.sort((a, b) => b.sub_date - a.sub_date)


    return (

        <Grid container item sm={12} sx={{m: "0 auto 10px", width: {xs: "100%", sm: 960}}}>
            {translators.map(t => {
                
                const translated = [...new Set(subbed.filter((m) => m.translators.includes(t.name)))]
                
                translated.sort((a, b) => b.sub_date - a.sub_date)

                const logo = t.name_exp + "_logo150.png"

                return (
                <Grid key={t.id} container sx={{...transConSx}}>
                <Grid container item sx={{...transLogoSx}}>
                    <div id={t.name_exp}>
                    <RouterLink className="trlogo" to={"/translators/" + t.name_exp.toLowerCase()} onClick={(e) => {e.preventDefault(); navigate("/translators/" + t.name_exp.toLowerCase())}}>
                    <LazyLoadImage
                    height="100%"
                    width="100%"
                    effect="blur"
                    placeholderSrc={"../translators/" + logo}
                    src={"../translators/" + logo}
                    />
                    </RouterLink>
                    </div>
                </Grid>
                <Grid container item sx={{...transMenuSx, ml: "5px"}}>
                    <Stack spacing={0} sx={{justifyContent: "space-evenly", textAlign: "left", height: "100%", width: {xs: "80%", sm: "70%", md: "60%"}}}>
                        <Grid>Subbed
                            <Grid sx={{textTransform: "none", fontSize: {xs: "1.8vw", md: 15}, fontWeight: 500}}>
                                <RouterLink className="list" to={"/translators/" + t.name_exp.toLowerCase()} onClick={(e) => {e.preventDefault(); navigate("/translators/" + t.name_exp.toLowerCase())}}>see full list</RouterLink>
                            </Grid>
                        </Grid>
                        <p>Support</p>
                        <p>Contact</p>
                    </Stack>
                    <Stack spacing={0} sx={{justifyContent: "space-evenly", textAlign: "center", height: "100%", width: {xs: "20%", sm: "30%", md: "40%"}}}>
                        <Grid sx={{textTransform: "none", fontSize: {xs: "3.8vw", md: 32}, fontWeight: 700}}>{translated.length}</Grid>
                        <Link href={t.support} target="_blank" sx={{height: {xs: "3.5vw", md: 30}, ":hover": {opacity: "0.7", transform: "scale(1.2)"}}}><img alt="" style={{alignSelf: "center"}} height="100%" src="../images/piggybank_gold.png"/></Link>
                        <Link href={t.contact} target="_blank" sx={{height: {xs: "3.5vw", md: 30}, ":hover": {opacity: "0.7", transform: "scale(1.2)"}}}><img alt="" style={{alignSelf: "center"}} height="100%" src="../logo/telegram_logo.png"/></Link>
                    </Stack>
                </Grid>
                <Grid container item xs sx={{height: {xs: "16.66vw", md: 153}, m: "0 auto"}}>
                {translated.slice(0, 5).map(m => {

                    const ids = JSON.parse(m.ids)
                    const posters = JSON.parse(m.posters)

                    return (
                    <Grid key={ids.imdb_id}  container item sx={{height: "100%", width: {xs: "10.5vw", md: 106.6}}}>
                        <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                            <LazyLoadImage
                            height="100%"
                            width="100%"
                            effect="blur"
                            style={{objectFit: "cover"}}
                            placeholderSrc={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                            src={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                            />
                        </RouterLink>
                    </Grid>
                )})}
                </Grid>
                
            </Grid>
                )}
            )}
        </Grid>
    )}

export default TranMd;
