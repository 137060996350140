import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography, Link} from "@mui/material"
import { mainHeaderSx, goback } from "../singleaw/awStyle"
import Sorter from './Sorter.tsx';
import axios from 'axios';
import SingleAwYear from './SingleAwYear.js';
import SingleAwNom from './SingleAwNom.js';

const SingleAw = () => {

    const navigate = useNavigate()

    const posterPath = '../../../posters/'

    const location = useLocation()

    const path = location.pathname.split("/")[2]
    const pathtype = location.pathname.split("/")[3]
    const param = location.pathname.split("/")[4]

    const [award, setAward] = useState([])
    const [awardY, setAwardY] = useState([])
    const [awardN, setAwardN] = useState([])
    const [years, setYears] = useState([])
    const [noms, setNoms] = useState([])

    const [params, setParams] = useState({
        type: pathtype,
        name: param
    })

    useEffect(() => {
        const getBase = async () => {
            const res = await axios.post(`/api/awards/${path}`, params, {
                responseType: 'json',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                }
              })
            setAward(res.data.data)
            setAwardY(res.data.yeared)
            setAwardN(res.data.nomed)
            setYears(res.data.years)
            setNoms(res.data.noms)
        }
        getBase()
    }, [params])

    // console.log(award)
    
    const f = {
        fontSize: {xs: "1.8vw", md: 15}, 
        fontWeight: 500
    }

    return (
                <>
                <Sorter award={award} setParams={setParams} location={location} params={params} param={param} years={years} noms={noms} pathtype={pathtype}/>
                {(awardY && pathtype === 'years') &&
                
                    awardY.map(aw => {
                        return (
                            <SingleAwYear key={aw.year+aw.name} aw={aw} navigate={navigate} posterPath={posterPath} setParams={setParams}/>
                        )})
                }
                {(awardN && pathtype === 'nominations') &&
                    <Grid sx={{m: '0 0 10px 0'}}>
                    {awardN.length > 0 && 
                    <Typography component="span" variant="h3">
                    <Grid container sx={mainHeaderSx}>
                        <div style={{margin: "auto 10px"}}>{awardN[0].name}</div>
                    </Grid>
                    </Typography>
                    }
                    {awardN.map(aw => {
                        return (
                            <SingleAwNom key={aw.year+aw.name} aw={aw} navigate={navigate} posterPath={posterPath} setParams={setParams}/>
                        )})}
                    </Grid>
                }
                </>
    )
}

export default SingleAw;
