import { Grid, Link } from '@mui/material';
import { Header, onlLogoSx, StreamWrap, StrText } from '../movieStyle';

const Streams = ({movie}) => {

    const streamings = JSON.parse(movie.streaming)
    // console.log(streamings)

    return (
            <StreamWrap>
                <Header>Watch online</Header>
                <div>
                {streamings.map((str) => {
                    return(
                        <Link className='sublogo' key={str.id} href={str.url + str.link} target="_blank" rel="noopener noreferrer"> 
                            <Grid component="img" src={"../logo/" + str.service + "_logo.png"} sx={onlLogoSx}></Grid>
                        </Link>
                    )
                })}
                {streamings.length !== 0 ? null : <StrText>No streaming available</StrText>} 
                </div>
            </StreamWrap>
    );
}

export default Streams;
