import { styled } from '@mui/material/styles';

const titleSx = {
    fontSize: { xs: "2.8vw", sm: "2.1vw", md: 20 }, 
    fontWeight: 700,
    lineHeight: 1.2,
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
}

const dirConSx = {
    color: "primary.main",
    width: "100%",
    m: "10px 0 0",
    // backgroundImage: bgTextureSecondary
}

const dirLogoSx = {
    height: {xs: "20vw", sm: "16.66vw", md: 150},
    width: {xs: "16vw", sm: "13.33vw", md: 120},
    maxHeight: 150,
    maxWidth: 120
}

const dirMenuSx = {
    padding: {xs: "0 10px", sm: "1.5vw 1.5vw", md: "15px 15px"},
    bgcolor: "rgba(237, 230, 219, 0.06)",
    width: {xs: "28vw", md: 300},
    fontSize: {xs: "2.3vw", sm: '1.7vw', md: 16},
    // fontWeight: 700,
    alignItems: "center",
    // textTransform: "uppercase"
}

const FlagWrap = styled('div')(({ theme }) => ({
    // paddingBottom: "5px",
    width: "100%",
    height: "2vw",
    [theme.breakpoints.up('sm')]: {
        minHeight: "13px",
        height: "1.65vw",
    },
    [theme.breakpoints.up('md')]: {
        height: "16px"
    }
}))

export {titleSx, dirConSx, dirLogoSx, dirMenuSx, FlagWrap}