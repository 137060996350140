import { Link as RouterLink } from "react-router-dom";
import { Grid, Card, Typography, CardMedia, CardContent, Stack, Link } from "@mui/material"
import { mainHeaderSx, mainSx, posterSx, titleSx, childSx, conSx, childtitleSx, coonSx, subLogoSx, fontBasicSx, placeSx, firstplaceSx, secondplaceSx, thirdplaceSx } from "../singleaw/awStyle"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SingleAwYear = ({aw, navigate, posterPath, setParams}) => {

    const getPath = () => {

        setParams({type: 'nominations', name: aw.c_coon_id})
        navigate("/events/" + aw.coon_id + "/nominations/" + aw.c_coon_id)
        }

    const sortNoms = aw.nominees
    sortNoms.sort((a,b) => a.place - b.place)

    const imdb_link = 'https://www.imdb.com/title/tt'

    return (
        
                <Grid sx={{m: '0 0 10px 0'}}>
                
                    <Typography component="span" variant="h3">
                    <Grid container sx={mainHeaderSx}>
                        <RouterLink className='left' to={"/events/" + aw.coon_id + "/nominations/" + aw.c_coon_id} onClick={(e) => {e.preventDefault(); getPath()}}>
                        <div style={{margin: "auto 10px"}}>{aw.name}</div>
                        </RouterLink>
                    </Grid>
                    </Typography>

                    <Grid container sx={{...conSx}}>
                    {sortNoms.map(n => {

                    let placecolor
                    n.place === 1 ? placecolor = firstplaceSx
                    : n.place === 2 ? placecolor = secondplaceSx
                    : placecolor = thirdplaceSx

                        
                    return (

                    (n.is_win || n.is_placed) &&
                    <Grid key={n.nom_id} container sx={{...mainSx}}>
                        <Card sx={{overflow: "visible", width: '100%', display: "flex", bgcolor: "transparent", color: "primary.main", boxShadow: "unset", padding: 0}}>
                            <CardMedia 
                                // id={ids.cin_id}
                                sx={{...posterSx}}
                            >
                                {n.cin_id ?
                                <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}>
                                    <LazyLoadImage
                                    effect="blur"
                                    placeholderSrc={posterPath + n.export_name + '_' + n.coon_default + '.jpg'}
                                    src={posterPath + n.export_name + '_' + n.coon_default + '.jpg'}
                                    height="100%"
                                    width="100%"
                                    style={{objectFit: "cover"}}
                                    />
                                </RouterLink>
                                :
                                <Link href={imdb_link + n.imdb_id} target="_blank" rel="noopener noreferrer">
                                    <LazyLoadImage
                                    effect="blur"
                                    placeholderSrc={"../../../images/no_poster.png"}
                                    src={"../../../images/no_poster.png"}
                                    height="100%"
                                    width="100%"
                                    style={{objectFit: "cover"}}
                                    />
                                </Link>
                                }
                            </CardMedia>
                            <CardContent sx={{width: {xs: '83vw', sm: '88%'}, "&:last-child": {padding: "0 0 0 10px"}}}>
                                <Grid container sx={{height: "100%", justifyContent: 'space-between'}}>
                                <Stack spacing={{xs:0}} sx={{height: "100%", width: '80%', justifyContent: "center"}}>
                                    <Grid item sx={{...titleSx}}>
                                        {n.cin_id ?
                                        <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}>
                                        {n.title_exp}
                                        </RouterLink>
                                        :
                                        <Grid sx={{color: 'silver'}}>
                                            <Link className='left' sx={{color: 'silver'}} href={imdb_link + n.imdb_id} target="_blank" rel="noopener noreferrer">
                                                {n.title_exp}
                                            </Link>
                                        </Grid>
                                        }
                                    </Grid>
                                    {n.cin_id ?
                                        <Grid item sx={{...fontBasicSx, padding: {xs: "5px 0", lg: "6px 0"}}}>{n.director}</Grid>
                                        :
                                        <Grid item sx={{...fontBasicSx, padding: {xs: "5px 0", lg: "6px 0"}, color: 'silver'}}>{n.director}</Grid>
                                        }
                                    <Grid item sx={fontBasicSx}>{n.imdb_genre}</Grid> 
                                    {n.is_placed && <Grid item sx={{...placeSx, ...placecolor}}>{n.place + " place"}</Grid>}
                                </Stack>
                                <Grid item sx={{alignSelf: 'end', height: '100%', padding: '10px'}}>
                                    <Stack spacing={{xs:0}} sx={{height: "100%", justifyContent: "space-between"}}>

                                    {(n.sub_lang && n.sub_lang === 'rus') ?
                                    <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}>
                                        <Grid 
                                            component="img" src={"../../../logo/sub_logo_rus70.png"} sx={{...subLogoSx, mt: 0}}>
                                        </Grid>
                                    </RouterLink>
                                    : (n.sub_lang && n.sub_lang === 'eng') ?
                                    <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}>
                                        <Grid 
                                            component="img" src={"../../../logo/sub_logo_eng70.png"} sx={{...subLogoSx, mt: 0}}>
                                        </Grid>
                                    </RouterLink>
                                    :
                                    <Grid 
                                        sx={{...subLogoSx, mt: 0}}
                                    />
                                    }
                                    
                                        <RouterLink className='bottom' to={"/events/" + aw.coon_id + "/nominations/" + aw.c_coon_id} onClick={(e) => {e.preventDefault(); getPath()}}>
                                            <Grid component="img" src={"../../../images/sad-coon_" + aw.coon_id + ".png"} sx={coonSx} />
                                        </RouterLink>
                                    </Stack>
                                </Grid>
                                </Grid>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    )})}
                    </Grid>

                    <Grid container sx={{...conSx}}>
                    {aw.nominees.map(n => {

                    {/* console.log(n) */}
                        
                    return (

                    (!n.is_win && !n.is_placed) &&
                    <Grid key={n.nom_id} container item sx={{...childSx}}>
                        <Card sx={{overflow: "visible", width: '100%', display: "flex", bgcolor: "transparent", color: "primary.main", boxShadow: "unset"}}>
                            <Grid 
                                sx={{...posterSx, height: 'inherit', display: 'flex'}}
                            >
                            {(n.sub_lang && n.sub_lang === 'rus') ?
                                <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}>
                                <Grid sx={subLogoSx}>
                                    <LazyLoadImage
                                    className="stream"
                                    effect="blur"
                                    placeholderSrc={"../../../logo/sub_logo_rus70.png"}
                                    src={"../../../logo/sub_logo_rus70.png"}
                                    height="100%"
                                    width="100%"
                                    style={{objectFit: "cover"}}
                                    />
                                </Grid>
                                </RouterLink>
                                : (n.sub_lang && n.sub_lang === 'eng') ?
                                <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}>
                                <Grid sx={subLogoSx}>
                                    <LazyLoadImage
                                    className="stream"
                                    effect="blur"
                                    placeholderSrc={"../../../logo/sub_logo_eng70.png"}
                                    src={"../../../logo/sub_logo_eng70.png"}
                                    height="100%"
                                    width="100%"
                                    style={{objectFit: "cover"}}
                                    />
                                </Grid>
                                </RouterLink>
                                :
                                null
                                }
                            </Grid>
                            <CardContent sx={{width: '80%', padding: 0, "&:last-child": {padding: 0}}}>
                                <Grid container sx={{height: "100%"}}>
                                <Stack spacing={{xs: 0.5, md: 1}} sx={{height: "100%", justifyContent: "center"}}>
                                    <Grid item sx={{...childtitleSx}}>
                                        {n.cin_id ?
                                        <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}>
                                        {n.title_exp}
                                        </RouterLink>
                                        :
                                        <Grid sx={{color: 'silver'}}>
                                            <Link className='left' sx={{color: 'silver'}} href={imdb_link + n.imdb_id} target="_blank" rel="noopener noreferrer">
                                            {n.title_exp}
                                            </Link>
                                        </Grid>
                                        }
                                    </Grid>
                                    {n.cin_id ?
                                        <Grid item sx={{fontSize: {xs: "1.7vw", sm: 13}}}>{n.director}</Grid>
                                        :
                                        <Grid item sx={{fontSize: {xs: "1.7vw", sm: 13}, color: 'silver'}}>{n.director}</Grid>
                                        }
                                    
                                </Stack>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                    )
                    
                    })}
                    </Grid>
                </Grid>
)
            
}

export default SingleAwYear;
