import { Grid } from '@mui/material';
import { AggLink, Logo, LogoWrap, rankSx, RateWrap, Score, ScoreWrap, Votes } from '../movieStyle';
import $ from "jquery";
import "./slider.css"
import { useEffect } from 'react';
// import AccountCircle from '@mui/icons-material/AccountCircle';
var roundSlider = require("round-slider")

const Ratings = ({movie, rank, yearRank}) => {

    const score = JSON.parse(movie.scores)
    const release = JSON.parse(movie.releaseinfo)
    const imdbLink = "https://www.imdb.com/title/tt"
    const rtLink = "https://www.rottentomatoes.com/m/"
    const metaLink = "https://www.metacritic.com/movie/"
    const lbxdLink = "https://www.letterboxd.com/film/"


    // let userScore = 0

    useEffect(() => {

        var refreshSliderSize = function () {
          var radius = this.control.parent().width() / 4.5;
          var width = radius / 9;
          // here you can restrict the radius and width
          // based on your application UI structure
          if (radius < 25) radius = 25;
        //   if (radius > 120) width = 22;
        //   else if (radius > 70) width = 18;
          this.option({ "radius": radius, "width": width });
        }

        $("#slider").roundSlider({
            svgMode: true,
            borderWidth: 0,
            // radius: "45%",
            handleSize: "+10",
            handleShape: "dot",
            sliderType: "min-range",
            editableTooltip: false,
            value: 50,
            // width: 5,

            create: function () {
                var that = this;
                refreshSliderSize.call(that);
            
                $(window).on("load", function () {
                  refreshSliderSize.call(that);
                });

                $(window).on("resize", function () {
                    refreshSliderSize.call(that);
                  });
              }
        })

        $("#slider").roundSlider("refreshTooltip");
    })

    let metascore, metavotes, rtscore, rtvotes, lbxdscore, lbxdvotes, lbxdstr
    score.meta_score ? metascore = score.meta_score : metascore = '-'
    score.meta_votes ? metavotes = score.meta_votes : metavotes = '-'
    score.rt_score ? rtscore = score.rt_score : rtscore = '-'
    score.rt_votes ? rtvotes = score.rt_votes : rtvotes = '-'
    score.lbxd_score ? lbxdscore = score.lbxd_score : lbxdscore = '-'
    lbxdscore != 0 ? lbxdscore = lbxdscore : lbxdscore = '-'
    score.lbxd_votes ? lbxdvotes = score.lbxd_votes : lbxdvotes = '-'
    
    lbxdstr = lbxdvotes.toString()
    // console.log(lbxdstr)

    if (lbxdstr.length > 6) {
        lbxdstr = lbxdstr = lbxdstr.substring(0, lbxdstr.length - 6) + " " 
            + lbxdstr.substring(1, lbxdstr.length - 3) + " " + lbxdstr.substring(4, lbxdstr.length)
    } else if (lbxdstr.length > 3) {
        lbxdstr = lbxdstr.substring(0, lbxdstr.length - 3) + " " 
     + lbxdstr.substring(lbxdstr.length - 3, lbxdstr.length)
    } else { lbxdstr = lbxdstr }

    // console.log(lbxdstr)
    
    return (
        <>
        <Grid container sx={rankSx}><span style={{marginRight: "20px"}}>
        #{yearRank} in {release.year}</span><span>#{rank} overall</span>
        </Grid>
        <Grid container sx={{padding: {xs: "2.7vw 0 1.7vw", md: "25px 0 15px"}, borderBottom: "1px solid #1E2D35"}}>
            
            <RateWrap>
                <LogoWrap>
                    <AggLink href={imdbLink + movie.ids.imdb_id} target="_blank" rel="noopener noreferrer">
                        <Logo src="../logo/imdb_logo.png"/>
                    </AggLink>
                </LogoWrap>
                <ScoreWrap><Score>{score.imdb_score}</Score><Votes>{score.imdb_votes_exp}</Votes></ScoreWrap>
            </RateWrap>
            <RateWrap>
                <LogoWrap>
                    <AggLink href={metaLink + movie.ids.meta_id} target="_blank" rel="noopener noreferrer">
                        <Logo src="../logo/meta_logo.png"/>
                    </AggLink>
                </LogoWrap>
                <ScoreWrap><Score>{metascore}</Score><Votes>{metavotes}</Votes></ScoreWrap>
            </RateWrap>
            <RateWrap>
                <LogoWrap>
                    <AggLink href={rtLink + movie.ids.rt_id} target="_blank" rel="noopener noreferrer">
                        <Logo src="../logo/rt_logo.png"/>
                    </AggLink>
                </LogoWrap>
                <ScoreWrap><Score>{rtscore}</Score><Votes>{rtvotes}</Votes></ScoreWrap>
            </RateWrap>
            <RateWrap>
                <LogoWrap>
                    <AggLink href={lbxdLink + movie.ids.lbxd_id} target="_blank" rel="noopener noreferrer">
                        <Logo src="../logo/lbxd_logo.png"/>
                    </AggLink>
                </LogoWrap>
                <ScoreWrap><Score>{lbxdscore}</Score><Votes>{lbxdstr}</Votes></ScoreWrap>
            </RateWrap>
            {/* <RateWrap>
                <LogoWrap>
                    <AccountCircle sx={{fontSize: {xs: "7vw", md: 55}}} />
                </LogoWrap>
                <ScoreWrap>
                    <div id="slider" style={{width: "100%"}}></div>
                </ScoreWrap>
            </RateWrap> */}
        </Grid>
        </>

    );
}

export default Ratings;
