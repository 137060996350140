import { Box } from '@mui/material';
import Navbar from '../components/navbar/Navbar';
import useWindowDimensions from "./../functions/windowDimensions"
import SubMD from '../components/subtitles/SubMD';
import SubXS from '../components/subtitles/SubXS';

const Subtitles = () => {

    const { clientWidth } = useWindowDimensions();


    return (
        <>
                <Navbar />
                <Box sx={{textAlign: "center"}}>

                    {(clientWidth > 600) ? 

                    <SubMD />

                    :

                    <SubXS />
                    
                    }
                </Box>
         </>
    )
}

export default Subtitles;


