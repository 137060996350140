import { Grid } from "@mui/material"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { menuPosterSx } from "./menuStyle"

export const MenuposterL = () => {

    return(
        <Grid container sx={{...menuPosterSx}}>
            <LazyLoadImage
            delayTime="50"
            height="100%"
            width="100%"
            effect="blur"
            style={{objectFit: "cover"}}
            placeholderSrc="../images/bench_dark_long_penart_shadow.jpg"
            src="../images/bench_dark_long_penart_shadow.jpg"
            />
            <Grid container sx={{fontSize: "85%", position: "absolute", justifyContent: "center", paddingBottom: "5px"}}>Procyonidae movie guide.</Grid>
        </Grid>
    )
}