import { Grid } from '@mui/material';
import { headlineSx } from "./menuStyle";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate, Link as RouterLink } from "react-router-dom";

const SubBlock = ({coonbase}) => {

    const navigate = useNavigate()

    // const base = [...new Set([...coonbase.filter(m => JSON.parse(m.subtitles).translators !== null)])]
    // const subbed = [...new Set([...base.map(m => {return ({...m, sub_add: new Date(JSON.parse(m.subtitles).sub_add)})})])]
    // subbed.sort((a, b) => b.sub_add - a.sub_add)

    const subbed = coonbase

    return (
        <Grid>
            <Grid container sx={headlineSx}>
                    <RouterLink className="left" to="/subtitles" onClick={(e) => {e.preventDefault(); navigate("subtitles")}}>
                    <div style={{margin: "auto 10px"}}>CHECK LATEST SUBTITLES</div>
                    </RouterLink>  
            </Grid>
            <Grid container item sm={12} sx={{m: "0 auto 10px", width: {xs: "100%", sm: 960}}}>
                <Grid container item xs sx={{height: {xs: "16.66vw", md: 153}, m: "0 auto"}}>
                    {subbed.slice(0, 9).map(m => {

                        const ids = JSON.parse(m.ids)
                        const main = JSON.parse(m.maininfo)
                        const release = JSON.parse(m.releaseinfo)
                        const posters = JSON.parse(m.posters)

                        return (
                        <Grid key={m.imdb_id}  container item sx={{height: "100%", width: {xs: "11.1vw", md: 106.6}}}>
                            <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                <LazyLoadImage
                                height="100%"
                                width="100%"
                                effect="blur"
                                title={main.imdb_title_exp + " (" + release.year + ")"}
                                style={{objectFit: "cover"}}
                                placeholderSrc={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                src={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                />
                            </RouterLink>
                        </Grid>
                        )
                    }
                    )}
                </Grid>
            </Grid>
        </Grid>

    )
}

export default SubBlock;
