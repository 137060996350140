import { useState, useEffect } from "react";
import axios from "axios"
import { Grid } from '@mui/material';
import { load } from 'cheerio'


function DonCheck({imdb_id, fulldon}) {

  const [fund, setFund] = useState({
    percent: 0
  })

  const [color, setColor] = useState("lightsalmon")

  useEffect(() => {
    const getFund = async () => {
        try {
            const res = await axios.post(`/api/donations/${imdb_id}`)

            
            const percent = res.data[0].percent
            // console.log(percent)

            setFund({
              sum: 0,
              percent: percent
            })

            if (percent > 66) {
              setColor("#7DCE82")
            } else if (percent > 33) {
              setColor("khaki")
            }

        }
        catch(error){
            console.error(error)
        }
    }
    getFund()
}, [])

    return (

        (fulldon > 99) ?
        <Grid sx={{color: "#8FDD92", fontSize: {xs: "2.6vw", sm: 14, md: 18}}}>{fulldon + "%"}</Grid>
        :
        <Grid sx={{color: {color}, fontSize: {xs: "2.6vw", sm: 14, md: 18}}}>{fund.percent + "%"}</Grid>
        
    )
}

export default DonCheck